import React, { useState, useEffect, useRef } from 'react';
import { Chart } from '../../../components';
import Services from '../../../service-utils/services';
import queryString from 'query-string';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { orderBy } from 'lodash';
import moment from 'moment';
import './CampaignTrendline.scss';
import { CHART_COLOR, getCurrencySymbol } from '../../../common/utils';
import { Button } from './../../../common';
import { Loader } from './../../../common';
import { dateFormatMoment } from '../../../components/format-date-moment';
import { externalTooltipHandler } from '../../../components/chart/index';
import { Table } from '../../../components';
import Paper from '@material-ui/core/Paper';
import isEqual from 'lodash/isEqual';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { handleMsgOnForm } from '../../../common/utils';
const chartFactorForCampaign = [
  { label: 'DAILY', value: 'DAILY' },
  { label: 'HOURLY', value: 'HOURLY' },
];

export const CampaignTrendline = ({ agencyId, selectedCampaign, payload }) => {
  let startDate = payload?.startDate; // Check if payload exists before accessing properties
  let endDate = payload?.endDate;
  let lineItemIds = payload?.lineItemIds;
  let creativeIds = payload?.creativeIds;
  let detectedDeviceTypes = payload?.detectedDeviceTypes;
  let detectedDeviceOs = payload?.detectedDeviceOs;
  let states = payload?.states;
  let countries = payload?.countries;
  const selectedCampaignId = selectedCampaign?.id;

  const [selectedDate, setSelectedDate] = useState([
    // moment().subtract(7, 'days').toDate(),
    // moment().toDate(),
  ]);
  const prevQueryStringParams = useRef(null);
  const [chartData, setChartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDates, setStartDate] = useState(new Date());
  const [endDates, setEndDate] = useState(null);
  const [currency, setCurrency] = useState();
  const [chartTypeForCampaign, setChartTypeForCampaign] = useState(chartFactorForCampaign[0]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});
  let interval = 'DAILY';
  const queryStringParams = {
    agencyId,
    startDate,
    endDate,
    interval,
    ...(lineItemIds && { lineItemIds }),
    ...(creativeIds && { creativeIds }),
    ...(detectedDeviceTypes && { detectedDeviceTypes }),
    ...(detectedDeviceOs && { detectedDeviceOs }),
    ...(states && { states }),
    ...(countries && { countries }),
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Fetch trendlineData
        const { data: fetchedTrendlineData } = await Services.trendLineByCampaign(
          selectedCampaignId,
          queryString.stringify(queryStringParams),
        );

        // Set trendlineData and chartData
        if (fetchedTrendlineData?.graphDataList) {
          setChartData(fetchedTrendlineData.graphDataList);
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching chart data:', error);
      }
    };

    // Check if the ending date is greater than the current date
    const currentDate = moment();
    const endDateMoment = moment(endDate);
    const startDateMoment = moment(startDate);

    if (endDateMoment.isAfter(currentDate)) {
      // Set the ending date to the current date
      setSelectedDate([startDate, currentDate]);
    }

    if (startDateMoment.isAfter(currentDate)) {
      setSelectedDate([currentDate, endDate]);
    }

    // Fetch data only if necessary conditions are met
    if (
      agencyId &&
      selectedCampaign &&
      startDate &&
      endDate &&
      selectedCampaignId &&
      queryStringParams &&
      !isEqual(queryStringParams, prevQueryStringParams.current)
    ) {
      fetchData();
    }

    // Update the previous queryStringParams for comparison in the next render
    prevQueryStringParams.current = queryStringParams;
  }, [
    agencyId,
    selectedCampaign,
    startDate,
    endDate,
    detectedDeviceOs,
    agencyId,
    lineItemIds,
    creativeIds,
    detectedDeviceTypes,
    states,
    countries,
    selectedCampaignId,
    queryStringParams,
  ]);

  const getDatesInRange = (startDate, endDate) => {
    const interval = chartTypeForCampaign.value;
    const dates = [];
    let currentDate = moment(startDate);
    const end = moment(endDate);
    const isHourlyInterval = interval === 'HOURLY';
    const formatString = isHourlyInterval ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
    const stepUnit = isHourlyInterval ? 'hour' : 'day';
    const step = isHourlyInterval ? 1 : 1;

    while (currentDate.isSameOrBefore(end, 'day')) {
      dates.push(currentDate.format(formatString));
      currentDate = currentDate.add(step, stepUnit);
    }

    // Filter out future dates
    const filteredDates = dates.filter((date) =>
      moment(date, formatString).isSameOrBefore(moment(), 'day'),
    );

    return filteredDates;
  };

  useEffect(() => {
    // Update the start and end dates to match the first and last data points in chartData
    if (chartData.length > 0) {
      const startDates = moment(chartData[0].label, 'DD/MM/YYYY HH:mm').toDate();
      const endDates = moment(chartData[chartData.length - 1].label, 'DD/MM/YYYY HH:mm').toDate();
      setStartDate(startDates);
      setEndDate(endDates);
    }
  }, [chartData]);

  const allDates = getDatesInRange(startDate, endDate);

  const sortedData = orderBy(chartData, ['label'], ['asc']) || [];
  useEffect(() => {
    // Find the first item with a valid currency and set it
    const firstItemWithCurrency = sortedData.find((item) => item.totalCost.currency);

    if (firstItemWithCurrency) {
      setCurrency(firstItemWithCurrency.totalCost.currency);
    }
  }, [sortedData]);
  const Data = {
    currency: currency,
    options: {
      scales: {
        x: {
          type: 'time',
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MMM DD', // Format for displaying the date
            },
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 7, // Maximum number of ticks to display
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            footer: function (tooltipItems) {
              return tooltipItems.map((tooltipItem) => {
                const selectedDate = parseInt(Data.labels[tooltipItem.index]);
                return dateFormatMoment(selectedDate, 'DD/MM/YYYY HH:mm');
              });
            },
          },
        },
      },
    },
    datasets: [
      {
        fill: false,
        label: 'Impressions',
        data: sortedData.map((item) => item.value),
        backgroundColor: CHART_COLOR.IMPRESSION,
        borderColor: CHART_COLOR.IMPRESSION,
        pointRadius: 0,
        borderWidth: 2,
        yAxisID: 'y',
      },
      {
        fill: false,
        label: 'Spend',
        data: sortedData.map((item) => item.totalCost.amount),
        backgroundColor: CHART_COLOR.SPEND,
        borderColor: CHART_COLOR.SPEND,
        pointRadius: 0,
        borderWidth: 2,
        yAxisID: 'y2',
      },

      {
        fill: false,
        label: 'Click Count',
        data: sortedData.map((item) => item.clickCount),
        backgroundColor: CHART_COLOR.CLICK_COUNT,
        borderColor: CHART_COLOR.CLICK_COUNT,
        pointRadius: 0,
        borderWidth: 2,
        yAxisID: 'y1',
      },
    ],

    labels: allDates,
  };
  const options = {
    scales: {
      yAxes: [
        {
          id: 'y',
          position: 'left',
          label: 'Number Of Impressions',
          color: CHART_COLOR.IMPRESSION,
        },
        {
          id: 'y1',
          position: 'left',
          label: 'Number Of Clicks',
          color: CHART_COLOR.CLICK_COUNT,
          grid: {
            drawOnChartArea: false,
          },
        },
        {
          id: 'y2',
          position: 'right',
          label: ` Spend ( ${getCurrencySymbol(currency)} )`,
          color: CHART_COLOR.SPEND,
          grid: {
            drawOnChartArea: false,
          },
        },
      ],
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          Button: 'Impressiom',
        },
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler,
        callbacks: {
          label: (tooltipItem) => {
            const {
              dataset: { label = '', data = [] },
              dataIndex,
            } = tooltipItem || {};

            let tooltipText = `${label} : ${data[dataIndex]}`;

            if (label === 'Spend') {
              tooltipText = `${label} : ${getCurrencySymbol(currency)} ${data[dataIndex]}`;
            }

            return tooltipText;
          },
        },
      },
    },
  };
  const getTableHeader = [
    {
      dataField: 'label',
      text: 'Date',
      formatter: (cell, row) => {
        const timestamp = parseInt(row.label);
        const date = new Date(timestamp);
        if (isNaN(date.getTime())) {
          return 'Invalid Date';
        }
        const formattedDate = format(date, 'dd/MM/yyyy');
        return formattedDate;
      },
    },
    // { dataField: 'adServedCount', text: 'Ad Served' },
    { dataField: 'impressionCount', text: 'Impressions' },
    { dataField: 'clickCount', text: 'Clicks' },
    {
      dataField: 'totalCost.amount',
      text: 'Spend',
    },
  ];

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    setPageNumber(page);
  };

  const handleDownload = async () => {
    try {
      // Ensure sortedData is not empty
      if (!sortedData || sortedData.length === 0) {
        console.error('No data to download.');
        return;
      }

      // Define custom column names
      const columnNames = ['Date', 'Delivered Impression', 'Clicks', 'Spend'];

      // Convert sortedData to CSV format
      const csvData = sortedData.reduce((acc, row) => {
        // Extract amount from totalCost or use an appropriate property
        const totalCostAmount = row.totalCost?.amount || 0;
        const formattedDate = row.label
          ? new Date(parseInt(row.label)).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          : '';

        // Map each column to a CSV value
        const rowData = [
          formattedDate,
          row.impressionCount || 0,
          row.clickCount || 0,
          totalCostAmount,
        ]
          .map((value) => (value !== null && value !== undefined ? `"${value}"` : ''))
          .join(',');

        return `${acc}${rowData}\n`;
      }, columnNames.join(',') + '\n'); // Use custom column names in the header

      // Create a Blob with the CSV data
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${selectedCampaign?.label}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      enqueueSnackbar('Downloaded successfully.', { variant: 'success' });
    } catch (error) {
      console.error('Error in handleDownload:', error);
      handleMsgOnForm(error, enqueueSnackbar, setServerErrors);
    }
  };

  return (
    <>
      <div className="dashboard-page">
        <div className="mb-3 d-flex filters"></div>
        <div className="graph-container">
          {isLoading && (
            <div className="loader-container">
              <Loader />
            </div>
          )}

          {!isLoading && <Chart type="Line" chartData={Data} options={options} height="90px" />}
        </div>
      </div>

      <Paper className="p-0 mn-h-200 chart-container">
        <div className="d-flex justify-content-between align-items-center pb-2">
          <Typography component="h5" variant="h6">
            Trendline Data
          </Typography>
          <Button
            variant="contained"
            className="btn btn-primary d-flex btn-38"
            startIcon={<CloudDownloadIcon />}
            onClick={handleDownload}
          ></Button>
        </div>
        <Table
          keyField="id"
          tableData={sortedData}
          tableHeader={getTableHeader}
          isRemote={false}
          onPageChangeFun={handleTableChange}
          totalSize={sortedData?.totalElements}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible"
          defaultSorted={[
            {
              dataField: 'firstName',
              order: 'asc',
            },
          ]}
        />
      </Paper>
    </>
  );
};
