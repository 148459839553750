import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Typography, Grid, Button } from '../../common';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import OverlapLoader from '../../common/loader/OverlapLoader';
import checkIcon from '../../assets/icon/check_icon.png';
import { setHeader } from '../../libs/redux-sdk/actions';
import Services from '../../service-utils/services';
import { Chip } from '@material-ui/core';

const RegistrationInvite = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [errorText, setErrorText] = useState();
  const [serverErrors, setServerErrors] = useState({});
  const [successStatus, setSuccessStatus] = useState(false);

  useEffect(() => {
    dispatch(
      setHeader({
        back: { text: 'Back' },
      }),
    );
  }, [dispatch]);

  const { mutate: createAgencyInvite, isLoading: isLoadingCreateUser } = useMutation(
    async (values) => {
      const { email } = values;
      const payload = { email };
      const { data } = await Services.AgencyInviteCreate(payload);
      return data;
    },
    {
      onError: (err) => {
        const errorMsg = err?.response?.data?.reasonDesc || 'Error in Registration.';
        // enqueueSnackbar(errorMsg, { variant: 'error' });
        setErrorText(errorMsg);
      },
      onSuccess: () => {
        // enqueueSnackbar('Invite Sent Successfully.', { variant: 'success' });
        setSuccessStatus(true);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required.'),
    }),
    onSubmit: () => createAgencyInvite(values),
  });

  return (
    <div className="registration-page">
      <div className="">
        {!successStatus ? (
          <>
            <div className="d-flex justify-content-center">
              <Typography variant="h6">Create your account</Typography>
            </div>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} className="mt-2">
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    helperText={touched.email && errors.email}
                    error={Boolean(touched.email && errors.email)}
                    label="Business E-mail"
                    name="email"
                    type="email"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('email', event.target.value)}
                    value={values.email}
                    variant="outlined"
                  />
                  {serverErrors.email && <p className="text-danger mt-n2">{serverErrors.email}</p>}
                </Grid>
                {!!errorText && <Chip label={errorText} className="alert-danger w-100 mb-3" />}
              </Grid>

              <Grid
                container
                spacing={3}
                alignItems="center"
                justifyContent="space-between"
                sx={{ mt: 4 }}
              >
                <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
                  <Typography
                    className="h6 cursor-pointer"
                    variant="body2"
                    onClick={() => history.push('/login')}
                    sx={{
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      textAlign: { xs: 'center', sm: 'left' },
                    }}
                  >
                    Already have an account? <span className="primary-color">Sign In</span>
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} sx={{ textAlign: { xs: 'center', sm: 'right' } }}>
                  <Button
                    variant="contained"
                    className="btn btn-primary"
                    type="submit"
                    disabled={isLoadingCreateUser || !values.email}
                    sx={{
                      fontSize: { xs: '0.875rem', sm: '1rem' },
                      width: '100%',
                      maxWidth: '200px',
                    }}
                  >
                    Sign Up
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        ) : (
          <div className="justify-content-center text-center">
            <Typography variant="h6">Invitation sent successfully to your email!</Typography>
            <img src={checkIcon} style={{ width: '50px' }} alt="Success" className="mt-3" />
            <Typography className="mt-3">
              Please check your email inbox for the invitation link and further instructions to
              complete your registration.
            </Typography>
            <Button
              variant="contained"
              className="btn btn-primary mt-3"
              onClick={() => history.push('/login')}
            >
              Back
            </Button>
          </div>
        )}
      </div>
      {isLoadingCreateUser && <OverlapLoader />}
    </div>
  );
};

export default RegistrationInvite;
