import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Grid, Button } from '@material-ui/core';
import { NewTextField } from '../../common';
import { useMutation } from 'react-query';
import queryString from 'query-string';
import { useSnackbar } from 'notistack';
import Services from '../../service-utils/services';
import { isEmpty } from 'lodash';
import { getSessionData } from '../../service-utils';
import { handleMsgOnForm } from '../../common/utils';
import { useParams } from 'react-router-dom';
import OverlapLoader from '../../common/loader/OverlapLoader';
const LandingFormData = (props) => {
  const { agencyId, advertiserId } = getSessionData();
  const { enqueueSnackbar } = useSnackbar();
  const { formId } = useParams();
  const { goToNextStep, isLoadingUpdateLeadForm, leadFormData } = props;
  // const { data: leadFormData = {} } = useQuery(
  //   ['LEADFORM_DATA', agencyId, advertiserId, formId],
  //   async () => {
  //     const payload = { agencyId };
  //     const response = await Services.getLeadFormById(
  //       advertiserId,
  //       formId,
  //       queryString.stringify(payload),
  //     );
  //     return response.data?.leadFormDTO;
  //   },
  //   { enabled: !!formId && !!agencyId },
  // );

  const { mutate: createOrUpdateLeadForm, isLoading: isLoadingCreateleadForm } = useMutation(
    async (values) => {
      const { name } = values;

      let formId = leadFormData?.id || null;
      let formFieldsList = leadFormData?.formFieldsList || null;
      let description = leadFormData?.description || null;
      let title = leadFormData?.title || null;
      let button = leadFormData.buttonName || null;
      let thankYouNote = leadFormData.thankYouNote || null;
      const payload = {
        name: name,
        formFieldsList: formFieldsList,
        description: description,
        title: title,
        buttonName: button,
        // published: publish,
        thankYouNote: thankYouNote,
      };
      const query = queryString.stringify({ agencyId });
      const response = !!formId
        ? await Services.leadFormUpdate(advertiserId, formId, query, payload)
        : await Services.leadFormCreate(advertiserId, query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar);
      },
      onSuccess: (data) => {
        if (!!formId) {
          enqueueSnackbar('LeadForm updated successfully.', { variant: 'success' });
        } else {
          enqueueSnackbar('LeadForm created successfully.', { variant: 'success' });
        }
        goToNextStep(data?.leadFormDTO?.id);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Lead Form Name is required.'),
    }),
    onSubmit: () => createOrUpdateLeadForm(values),
  });

  useEffect(() => {
    if (!isEmpty(leadFormData)) {
      const { name } = leadFormData;
      setFieldValue('name', name);
    }
  }, [setFieldValue, leadFormData]);

  return (
    <div className="pt-3 p-4 mn-h-200 LI-creation-tab">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} className="py-0">
            <NewTextField
              required
              info="Give a suitable lead form name. e.g Admission Form, Sales Form"
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              label="Lead Form Name"
              margin="normal"
              name="name"
              onBlur={handleBlur}
              onChange={(event) => setFieldValue('name', event.target.value)}
              value={values.name}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <div className="mt-4 d-flex justify-content-end ">
          <Button variant="contained" className="btn btn-secondary d-flex">
            Cancel
          </Button>
          {formId && (
            <Button
              variant="contained"
              className="btn btn-secondary d-flex ml-4"
              onClick={() => {
                goToNextStep(formId);
              }}
            >
              Next
            </Button>
          )}
          <Button
            variant="contained"
            type="submit"
            disabled={leadFormData.expired || isLoadingUpdateLeadForm}
            className="btn btn-primary d-flex ml-4"
          >
            {formId
              ? isLoadingUpdateLeadForm
                ? 'Updating'
                : 'Update And Next'
              : isLoadingUpdateLeadForm
              ? 'Creating'
              : 'Create And Next'}
          </Button>
          {isLoadingCreateleadForm && <OverlapLoader />}
        </div>
      </form>
    </div>
  );
};

export default LandingFormData;
