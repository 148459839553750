import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { Table, SearchInput } from '../../components';
import { Button, NewSelect, Loader } from '../../common';
import { useHistory, useLocation } from 'react-router-dom';
import Services from '../../service-utils/services';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import getSessionData from './../../service-utils/session-util';
import { isEmpty, orderBy } from 'lodash';
import Chip from '@material-ui/core/Chip';
import { Dropdown } from 'react-bootstrap';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { handleMsgOnForm } from '../../common/utils';
import { ConformationBox } from '../../components/conformationBox';
import { format } from 'date-fns';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import CreateIcon from '../../components/create-icon/createIcon';
import {
  CloneButton,
  DeleteButton,
  SearchButton,
  ViewButton,
} from '../../components/react-icons/ReactIcons';
export const Creative = () => {
  let { agencyId } = getSessionData();
  const { advertiserId } = useAdvertiser();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [advertiser, setAdvertiser] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchText, setSearchText] = useState();
  const [selectedAdvertiser, setSelectedAdvertiser] = useState();
  const [published, setPublished] = useState(false);
  const [creativeType, setCreativeType] = useState({
    label: 'Banner',
    value: 'BANNER',
  });
  const [advertiserOptions, setAdvertiserOptions] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const queryParams = queryString.parse(location.search);
  const { name, publishStatus, type } = queryParams;

  // const totalSize = 100;
  const handleAddCreatives = () => {
    history.push(`/advertiser/creative-create`);
  };

  const { data: allAdvertiser = [], isLoading: isLoadingAllAdv } = useQuery(
    ['ALL_ADVERTISERS', agencyId],
    async () => {
      const queryStringParams = {
        pageSize: 5000,
        pageNum: 0,
        agencyId,
      };
      const response = await Services.advertisersGetAll(queryString.stringify(queryStringParams));
      return response.data?.advertiserList;
    },
    { enabled: !!agencyId },
  );

  const {
    data,
    isLoading: isLoadingCreativeData,
    refetch,
  } = useQuery(
    ['ALL_CREATIVES', pageNumber, pageLimit, advertiserId, publishStatus, type, name],
    async () => {
      const queryStringParams = {
        //  advertiserId,
        agencyId,
        type,
        name,
        published: publishStatus,
        pageNum: pageNumber - 1, // Update the page number here
        pageSize: pageLimit,
      };
      const response = await Services.creativeSearch(
        advertiserId,
        queryString.stringify(queryStringParams),
      );
      return response.data || [];
    },
    {
      enabled: !!advertiserId || pageNumber !== 1, // Ensure it's enabled for the first page load
    },
  );

  const handleApply = useCallback(() => {
    if (!isEmpty(selectedAdvertiser)) {
      let payload = {
        advertiserId: selectedAdvertiser.id,
        name: searchText || '',
        publishStatus: published.value,
        type: creativeType.value,
      };
      setPageNumber(1);
      history.push({
        pathname: '/advertiser/creative',
        search: queryString.stringify(payload),
      });
    }
  }, [selectedAdvertiser, searchText, creativeType.value, published.value, history]);

  useEffect(() => {
    if (!isEmpty(allAdvertiser)) {
      const activeAdvertiser =
        orderBy(
          allAdvertiser?.map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
            disabled: item.status,
            modifiedDate: item.modifiedDate,
          })),
          [(advertiser) => advertiser.label.toLowerCase()],
          ['asc'],
        ) || [];
      if (!isEmpty(advertiserId)) {
        const selectedAdv = activeAdvertiser.find((item) => item.id === parseInt(advertiserId));
        setSelectedAdvertiser(selectedAdv);
      } else {
        setSelectedAdvertiser(activeAdvertiser[0]);
      }
      setAdvertiserOptions(activeAdvertiser);
    }
  }, [allAdvertiser, advertiserId]);

  useEffect(() => {
    if (!isEmpty(selectedAdvertiser) && !advertiserId) {
      handleApply();
    }
  }, [selectedAdvertiser, advertiserId, handleApply]);

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Creative
          </div>
        ),
      }),
    );
  }, [dispatch, selectedAdvertiser, advertiserOptions]);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    const updatedParams = {
      ...queryString.parse(location.search),
      pageNum: page - 1, // adjust for 0-based index
      pageSize: sizePerPage,
    };
    history.replace({ search: queryString.stringify(updatedParams) });
  };
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const parsedPageNum = Number(queryParams.pageNum);
    if (!isNaN(parsedPageNum) && parsedPageNum + 1 !== pageNumber) {
      setPageNumber(parsedPageNum + 1);
    }
    setPageLimit(Number(queryParams.pageSize) || 10);
  }, [location.search]);

  const handleEdit = (id) => {
    if (id) {
      history.push(`/advertiser/creative/view-creatives/${id}?advertiserId=${advertiserId}`);
    }
  };

  const handleClone = (id) => {
    if (id) {
      history.push(`/advertiser/creative-clone/${id}`);
    }
  };

  const handleIcons = (row) => {
    const { id } = row;
    return (
      <div className="flex items-center gap-2">
        <ViewButton handleView={() => handleEdit(id)} />
        <CloneButton handleApply={() => handleClone(id)} />
        <DeleteButton handleDelete={() => setDeleteId(id)} />
      </div>
    );
  };

  // const manageButton = (row) => {
  //   return (
  //     <>
  //       <Dropdown id="dropdown-item-button" className="action-dropdown btn-action ">
  //         <Dropdown.Toggle variant="link">
  //           <MoreHorizIcon />
  //         </Dropdown.Toggle>
  //         <Dropdown.Menu className="shadow-lg">
  //           {row.published ? (
  //             <Dropdown.Item onClick={() => handleEdit(row.id)}>View</Dropdown.Item>
  //           ) : (
  //             <Dropdown.Item onClick={() => handleEdit(row.id)}>View</Dropdown.Item>
  //           )}
  //           <Dropdown.Item onClick={() => handleClone(row.id)}>Clone</Dropdown.Item>
  //           <Dropdown.Item onClick={() => setDeleteId(row.id)}>Delete</Dropdown.Item>
  //         </Dropdown.Menu>
  //       </Dropdown>
  //     </>
  //   );
  // };

  const tableData = useMemo(() => {
    return data?.creativeDTOList.map((item) => {
      return {
        ...item,
        type: !isEmpty(item.bannerDTO) ? 'Banner' : 'Video',
      };
    });
  }, [data]);

  const getTableHeader = [
    //{ dataField: 'id', text: 'ID' },
    {
      dataField: 'name',
      text: 'Creative Name',
      formatter: (col, row) => (
        <a href={`/advertiser/creative/view-creatives/${row.id}?advertiserId=${advertiserId}`}>
          {col}
        </a>
      ),
    },
    {
      dataField: 'masterBannerSizeDTO.formatName',
      text: 'Size',
      formatter: (col, row) =>
        row.bannerDTO?.masterBannerSizeDTO ? row.bannerDTO?.masterBannerSizeDTO.formatName : '',
    },

    {
      dataField: 'published',
      text: 'Status',
      formatter: (col, row) =>
        col ? (
          <Chip label="Published" className="alert-success" />
        ) : (
          <Chip label="Un-Published" className="alert-danger" />
        ),
    },
    {
      dataField: 'modifiedDate',
      text: 'Modified Date',
      formatter: (col, row) => {
        const formattedDate = format(new Date(col), 'dd/MM/yyyy');
        return formattedDate;
      },
    },

    // {
    //   dataField: 'id',
    //   text: '',
    //   formatter: (col, row) => manageButton(row),
    // },
    {
      dataField: 'id',
      text: '',
      formatter: (col, row) => handleIcons(row),
    },
  ];
  const [serverErrors, setServerErrors] = useState({});

  const { mutate: handleDelete } = useMutation(
    async (id) => {
      const query = queryString.stringify({ agencyId });
      const response = await Services.creativeDelete(advertiserId, id, query);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
        setDeleteId();
      },
      onSuccess: () => {
        enqueueSnackbar('Ceative deleted successfully.', { variant: 'success' });
        setDeleteId();
        refetch();
      },
    },
  );
  const handleButtonClick = () => {
    history.push('/advertiser/creative-create');
  };
  return (
    <>
      <div className="mb-3 d-flex justify-content-between filters">
        <div className="d-flex">
          <SearchInput
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
          />
          {/* <NewSelect
            required
            options={advertiserOptions}
            value={selectedAdvertiser}
            onChange={(obj) => setSelectedAdvertiser(obj)}
            placeholder={`Advertiser`}
            isMulti={false}
            className="mt-n3 ml-3 "
          /> */}
          <NewSelect
            options={[
              {
                label: 'Published',
                value: true,
              },
              {
                label: 'Un-published',
                value: false,
              },
            ]}
            value={published}
            onChange={(obj) => setPublished(obj)}
            placeholder={`Publish status`}
            isMulti={false}
            className="mt-n3 ml-3 "
          />
          <NewSelect
            options={[
              {
                label: 'Banner',
                value: 'BANNER',
              },
              {
                label: 'Video',
                value: 'VIDEO',
              },
            ]}
            value={creativeType}
            onChange={(obj) => setCreativeType(obj)}
            placeholder={`Type`}
            isMulti={false}
            className="mt-n3 ml-3 "
          />
          <Button
            variant="contained"
            className="btn btn-primary d-flex btn-38 ml-3"
            startIcon={<SearchButton />}
            onClick={handleApply}
          >
            Apply
          </Button>
        </div>
        {/* <Button
          variant="contained"
          className="btn btn-primary d-flex btn-38"
          // startIcon={<AddIcon />}
          onClick={() => handleAddCreatives()}
        >
          New Creative
        </Button> */}
        <div>
          <CreateIcon label="Create New Creative" handleAdd={handleAddCreatives} />
        </div>
      </div>
      {isLoadingAllAdv || isLoadingCreativeData ? (
        <Loader />
      ) : (
        <Table
          keyField="id"
          tableData={tableData}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={data?.totalElements}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible"
          defaultSorted={[
            {
              dataField: 'firstName',
              order: 'asc',
            },
          ]}
          additionalText="Upload your creative materials to kick off your advertising efforts. This step is essential for setting up and managing campaigns that effectively reach your target audience."
          onButtonClick={handleButtonClick}
          buttonLabel="Click here to new creative"
        />
      )}
      <ConformationBox
        isOpen={!!deleteId}
        handleClose={() => setDeleteId()}
        handleSubmit={() => handleDelete(deleteId)}
        title="Delete ?"
        subtitle="Are you sure you want to delete this creative?"
        btnCloseLabel="Close"
        btnSubmitLabel="Delete"
      />
    </>
  );
};
