import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { List, ListItem, ListItemIcon } from '@material-ui/core';
import history from '../../../history';
import SpeakerGroupRoundedIcon from '@material-ui/icons/SpeakerGroupRounded';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import ImportantDevicesRoundedIcon from '@material-ui/icons/ImportantDevicesRounded';
import CategoryRoundedIcon from '@material-ui/icons/CategoryRounded';
import PeopleIcon from '@material-ui/icons/People';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import getSessionData from '../../../service-utils/session-util';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import BarChartIcon from '@material-ui/icons/BarChart';
import SettingsIcon from '@material-ui/icons/Settings';
import SegmentIcon from '../../../assets/icon/segmentIcon.png';
import NetworkCheckIcon from '@material-ui/icons/NetworkCheck';
import { LocateFixed, MonitorCheck, MapPinCheck } from 'lucide-react';

const SideNavigationMenu = React.memo(function SideNavigationMenu(props) {
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const {
    open,
    history: {
      location: { pathname },
    },
  } = props;
  const { roles } = getSessionData();
  const logout = () => {
    console.log('logout');
  };

  const menuFunction = () => {
    // const isAdvertiserPage = pathname.includes('/advertiser');
    let dashboardMenu = [];

    if (!pathname.includes('/advertiser')) {
      dashboardMenu.push(
        {
          id: 2,
          active: pathname.includes('/dashboard'),
          title: 'Dashboard',
          link: '/dashboard',
          icon: <DashboardRoundedIcon />,
        },
        {
          id: 2,
          active: pathname.includes('/customer'),
          title: 'Advertiser',
          link: '/customer',
          icon: <SpeakerGroupRoundedIcon />,
        },
      );

      if (roles.includes('SUPERADMIN')) {
        dashboardMenu.push(
          {
            id: 5,
            active: pathname.includes('/agency'),
            title: 'Agency',
            link: '/agency',
            icon: <BusinessCenterIcon />,
          },
          {
            id: 6,
            active: pathname.includes('/user'),
            title: 'Users',
            link: '/user',
            icon: <PeopleIcon />,
          },
          {
            id: 7,
            active: pathname.includes('/update-agency'),
            title: 'Settings',
            link: '/update-agency',
            icon: <SettingsIcon />,
          },
        );
      } else if (roles.includes('ADMIN')) {
        // Your code here
        dashboardMenu.push(
          {
            id: 2,
            active: pathname.includes('/conversion'),
            title: 'Conversion',
            link: '/conversion',
            icon: <BarChartIcon />,
          },
          {
            id: 5,
            active: pathname.includes('/agency/billing/'),
            title: 'Billing',
            link: `/agency-billing/account`,
            icon: <BusinessCenterIcon />,
          },
          {
            id: 6,
            active: pathname.includes('/user'),
            title: 'Users',
            link: '/user',
            icon: <PeopleIcon />,
          },
        );
      }
      if (roles.includes('OWNER')) {
        // Your code here
        dashboardMenu.push(
          {
            id: 2,
            active: pathname.includes('/conversion'),
            title: 'Conversion',
            link: '/conversion',
            icon: <BarChartIcon />,
          },
          {
            id: 5,
            active: pathname.includes('/agency-billing/account'),
            title: 'Billing',
            link: `/agency-billing/account`,
            icon: <BusinessCenterIcon />,
          },
          {
            id: 6,
            active: pathname.includes('/user'),
            title: 'Users',
            link: '/user',
            icon: <PeopleIcon />,
          },
          {
            id: 7,
            active: pathname.includes('/update-agency'),
            title: 'Settings',
            link: '/update-agency',
            icon: <SettingsIcon />,
          },
        );
      }
    }

    if (pathname.includes('/advertiser')) {
      dashboardMenu.push(
        {
          id: 2,
          active: pathname.includes('/advertiser/dashboard'),
          title: 'Dashboard',
          link: '/advertiser/dashboard',
          icon: <DashboardRoundedIcon />,
        },
        {
          id: 2,
          active:
            pathname.includes('/advertiser/campaign') || pathname.includes('/advertiser/line-item'),
          title: 'Campaign',
          link: '/advertiser/campaign',
          icon: <ImportantDevicesRoundedIcon />,
        },
        {
          id: 4,
          active: pathname.includes('/advertiser/creative'),
          title: 'Creative',
          link: '/advertiser/creative',
          icon: <CategoryRoundedIcon />,
        },
        // {
        //   id: 4,
        //   active: pathname.includes('/advertiser/lead-form'),
        //   title: 'Lead Form',
        //   link: '/advertiser/lead-form',
        //   icon: <PeopleOutlineIcon />,
        // },
        {
          id: 4,
          active: pathname.includes('/advertiser/inventory'),
          title: 'Inventory',
          link: '/advertiser/inventory',
          icon: <LocalOfferIcon />,
        },

        {
          id: 4,
          active: pathname.includes('/advertiser/report'),
          title: 'Report',
          link: '/advertiser/report',
          icon: <AssessmentOutlinedIcon />,
        },
        // {
        //   id: 4,
        //   active: pathname.includes('/advertiser/lead-report'),
        //   title: 'Lead Report',
        //   link: '/advertiser/lead-report',
        //   icon: <AssignmentIndIcon />,
        // },
        {
          id: 4,
          active: pathname.includes('/advertiser/geofence'),
          title: 'Geofencing',
          link: '/advertiser/geofence',
          icon: <LocateFixed />,
        },
        {
          id: 4,
          title: 'Segmentation',
          // icon: <LayersIcon />,
          icon: <img src={SegmentIcon} alt="Segment Icon" style={{ width: 35, height: 35 }} />,
          subMenu: [
            {
              id: 1,
              active: pathname.includes('/advertiser/pincode-segment'),
              title: 'Pincode',
              link: '/advertiser/pincode-segment',
              icon: <MapPinCheck />, // Updated icon
            },

            {
              id: 2,
              active: pathname.includes('/advertiser/ip-segment'),
              title: 'Ip Address',
              link: '/advertiser/ip-segment',
              icon: <NetworkCheckIcon />,
            },
            {
              id: 3,
              active: pathname.includes('/advertiser/deviceId-segment'),
              title: 'Device Id',
              link: '/advertiser/deviceId-segment',
              icon: <MonitorCheck />,
            },
          ],
        },
      );
    }

    const sideMenusItems = [...dashboardMenu];
    const sortedMenu = sideMenusItems.filter((el) => el).sort((a, b) => a.id - b.id);

    return sortedMenu;
  };

  const menusList = menuFunction();
  const menus = menusList;

  const onClickMenu = (menu) => {
    if (menu.title === 'Logout') {
      logout();
    }
    // Toggle open/close state of submenu
    if (menu.subMenu) {
      setOpenSubMenu(openSubMenu === menu.title ? null : menu.title);
    } else {
      history.push(menu?.link);
    }
  };

  return (
    <>
      <List>
        {menus.map((menu, index) => {
          return (
            <div key={index}>
              <ListItem
                button
                key={index}
                className={menu.active ? 'active-menu' : ''}
                onClick={() => onClickMenu(menu)}
                disableRipple
              >
                <ListItemIcon>{menu.icon}</ListItemIcon>
                {open && (
                  <div className="menu ml-2">
                    <label>{menu.title}</label>
                  </div>
                )}
              </ListItem>
              {/* Render submenu if current menu item is open */}
              {menu.subMenu && openSubMenu === menu.title && (
                <List disablePadding>
                  {menu.subMenu.map((subMenuItem, subIndex) => (
                    <ListItem
                      button
                      key={subIndex}
                      className={subMenuItem.active ? 'active-menu' : ''}
                      onClick={() => onClickMenu(subMenuItem)}
                      disableRipple
                      style={{ paddingLeft: 30 }}
                    >
                      <ListItemIcon>{subMenuItem.icon}</ListItemIcon>
                      {open && (
                        <div className="menu ml-2">
                          <label>{subMenuItem.title}</label>
                        </div>
                      )}
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          );
        })}
      </List>
    </>
  );
});

export default withRouter(SideNavigationMenu);
