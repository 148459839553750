import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { Table, SearchInput } from '../../components';
import { Button, Loader, NewSelect } from '../../common';
import Services from '../../service-utils/services';
import getSessionData from '../../service-utils/session-util';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import { Chip } from '@material-ui/core';
import { GEOFENCING_STATUS } from '../../libs/constants';
import CreateIcon from '../../components/create-icon/createIcon';
import { EditButton, SearchButton } from '../../components/react-icons/ReactIcons';
const Geofencing = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [appliedSearchText, setAppliedSearchText] = useState('');
  const [advertiserOptions, setAdvertiserOptions] = useState([]);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  let { agencyId } = getSessionData();
  const { advertiserId } = useAdvertiser();
  const [status, setStatus] = useState(GEOFENCING_STATUS[0]);
  const [appliedStatus, setAppliedStatus] = useState(GEOFENCING_STATUS[0]);
  const navigator = '/advertiser/geofence-create';
  const { data, isLoading } = useQuery(
    [
      'GEOFENCING',
      advertiserId,
      pageLimit,
      pageNumber,
      agencyId,
      appliedSearchText,
      appliedStatus.value,
    ],
    async () => {
      const queryStringParams = {
        pageNum: pageNumber - 1,
        pageSize: pageLimit,
        agencyId,
        location: appliedSearchText,
        enabled: appliedStatus.value, // Use applied status here
      };
      const response = await Services.searchGeoFencing(
        advertiserId,
        queryString.stringify(queryStringParams),
      );
      return response.data;
    },
    { enabled: !!agencyId },
  );

  const handleApply = useCallback(() => {
    const updatedParams = {
      ...queryString.parse(location.search),
      pageNum: 0,
    };
    history.replace({ search: queryString.stringify(updatedParams) });
    setAppliedSearchText(searchText);
    setAppliedStatus(status);
    setPageNumber(1);
  }, [searchText, status, setPageNumber]);

  const handleAddGeofencing = () => {
    history.push(navigator);
  };

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Geofencing
          </div>
        ),
      }),
    );
  }, [dispatch, selectedAdvertiser, advertiserOptions]);
  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    setPageNumber(page);
    const updatedParams = {
      ...queryString.parse(location.search),
      pageNum: page - 1, // adjust for 0-based index
      pageSize: sizePerPage,
    };
    history.replace({ search: queryString.stringify(updatedParams) });
  };

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const parsedPageNum = Number(queryParams.pageNum);
    if (!isNaN(parsedPageNum) && parsedPageNum + 1 !== pageNumber) {
      setPageNumber(parsedPageNum + 1);
    }
    setPageLimit(Number(queryParams.pageSize) || 10);
  }, [location.search, pageNumber, setPageNumber, setPageLimit]);

  const tableData = useMemo(() => {
    return data?.masterGeofencingList || [];
  }, [data]);

  const handleEdit = (id) => {
    if (id) {
      history.push(`${navigator}/${id}`);
    }
  };
  const handleIcons = (row) => {
    const { id } = row;
    return (
      <div className="flex items-center gap-2">
        <EditButton handleEdit={() => handleEdit(id)} />
      </div>
    );
  };
  const manageButton = (row) => {
    return (
      <>
        <Dropdown id="dropdown-item-button" className="action-dropdown btn-action ">
          <Dropdown.Toggle variant="link">
            <MoreHorizIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu className="shadow-lg">
            <Dropdown.Item onClick={() => handleEdit(row.id)}>Edit</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const getTableHeader = [
    {
      dataField: 'location',
      text: 'Location Name',
      formatter: (col, row) => <a href={`${navigator}/${row.id}`}>{col}</a>,
    },
    {
      dataField: 'longitude',
      text: 'Longitude',
    },
    {
      dataField: 'latitude',
      text: 'Latitude',
      // formatter: (col, row) => col,
    },
    {
      dataField: 'radius',
      text: 'Radius (KM)',
      // formatter: (col, row) => col,
    },

    {
      dataField: 'enabled',
      text: 'Status',
      formatter: (col, row) =>
        col ? (
          <Chip label="Enabled" className="alert-success" />
        ) : (
          <Chip label="Disabled" className="alert-danger" />
        ),
    },
    // {
    //   dataField: 'id',
    //   text: '',
    //   formatter: (col, row) => manageButton(row),
    // },
    {
      dataField: 'id',
      text: '',
      formatter: (col, row) => handleIcons(row),
    },
  ];
  const handleButtonClick = () => {
    history.push(navigator);
  };
  return (
    <>
      <div className="mb-3 d-flex justify-content-between filters">
        <div className="d-flex">
          <SearchInput
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText || ''}
          />
          <NewSelect
            required
            options={GEOFENCING_STATUS}
            value={status}
            onChange={(obj) => setStatus(obj)}
            placeholder={`Status`}
            isMulti={false}
            className="mt-n3 ml-3 "
          />
          <Button
            variant="contained"
            className="btn btn-primary d-flex btn-38 ml-3"
            startIcon={<SearchButton />}
            onClick={handleApply}
          >
            Apply
          </Button>
        </div>
        <div>
          <CreateIcon label="Create New Geofence" handleAdd={handleAddGeofencing} />
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          keyField="id"
          tableData={tableData}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={data?.totalElements}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible"
          defaultSorted={[
            {
              dataField: 'firstName',
              order: 'asc',
            },
          ]}
          additionalText="No Geofence found. Please create a Geofence to get started."
          onButtonClick={handleButtonClick}
          buttonLabel="Click here to create New Geofence"
        />
      )}
    </>
  );
};
export default Geofencing;
