import React from 'react';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import getSessionData from './../../../service-utils/session-util';
import CookieUtil from './../../../service-utils/cookie-util';
import history from '../../../history';
import Services from '../../../service-utils/services';
import { useMutation, useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import Popover from '@material-ui/core/Popover';
import { CONTENT_URL } from '../../../common/constants';
export function UserMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'account-menu' : undefined;
  const { userFullName, agencyId } = getSessionData();

  const { removeAllCookie } = CookieUtil();
  const { enqueueSnackbar } = useSnackbar();

  const { data: logoUrl = {} } = useQuery(
    ['AGENCY_LOGO_DATA', agencyId],
    async () => {
      const response = await Services.getAgencyLogo(agencyId);
      return response?.data;
    },
    { enabled: !!agencyId },
  );
  const { mutate: logoutUser } = useMutation(
    async () => {
      const query = queryString.stringify({ agencyId });
      const response = await Services.userLogout(query);
      return response.data;
    },
    {
      onError: (err) => {
        enqueueSnackbar('Error.', { variant: 'error' });
        removeAllCookie();
        history.replace('/');
      },
      onSuccess: (data) => {
        enqueueSnackbar('Logout successfully.', { variant: 'success' });
        removeAllCookie();
        history.replace('/');
      },
    },
  );

  const handleLogout = () => {
    logoutUser();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  // const handleCloseOutside = () => {
  //   setAnchorEl(null);
  // };

  const currentPath = history.location.pathname;
  // const isCustomerPage = currentPath.startsWith('/advertiser');
  const updateProfilepath = currentPath.startsWith('/update-profile');
  const updatePasswordpath = currentPath.startsWith('/update-password');
  // const updateAgencypath = currentPath.startsWith('/update-agency');
  // const notificationPath = currentPath.startsWith('/notification');
  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return text?.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <>
      <div>
        <Box>
          <Tooltip
            className="AvtarButton"
            title={
              <>
                <Typography variant="body1" className="text-white">
                  Adzylytics Account
                </Typography>
                <Typography variant="body2" className="text-white">
                  {userFullName}
                  <br />
                </Typography>
              </>
            }
          >
            <div className="d-flex justify-content-center align-items-center">
              {logoUrl && (
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="pr-2"
                    src={`${CONTENT_URL}/${logoUrl}`}
                    // alt="Agency Logo"
                    style={{ maxWidth: '150px', maxHeight: '40px' }}
                  />
                </div>
              )}
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={id}
                aria-haspopup="true"
              >
                <Avatar className="mainAvatar">
                  {/* {userFullName && userFullName.charAt(0).toUpperCase()} */}
                </Avatar>
              </IconButton>
            </div>
          </Tooltip>
        </Box>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          PaperProps={{
            style: {
              borderRadius: '20px',
              marginTop: '5px', // Adjust margin to move it down
            },
          }}
        >
          <MenuItem className="mt-2">
            <Typography className="ml-2 font-weight-bold">
              Hi, {truncateText(userFullName, 15)}
            </Typography>
          </MenuItem>

          {!updateProfilepath && (
            <MenuItem
              className="mt-2"
              onClick={() => {
                history.push('/update-profile');
                handleClose();
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Avatar className="mainAvatar" style={{ color: '#dd6633' }}>
                {userFullName && userFullName.charAt(0).toUpperCase()}
              </Avatar>
              <Typography className="ml-3">Update Profile</Typography>
            </MenuItem>
          )}

          <Divider />
          {!updatePasswordpath && (
            <MenuItem
              onClick={() => {
                history.push(`/update-password`);
                handleClose();
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ListItemIcon>
                <LockOpenIcon fontSize="small" className="ml-2" />
              </ListItemIcon>
              <Typography className="ml-3">Change Password</Typography>
            </MenuItem>
          )}
          <MenuItem onClick={() => handleLogout()}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" className="ml-2" />
            </ListItemIcon>
            <Typography className="ml-3">Logout</Typography>
          </MenuItem>
        </Popover>
      </div>
    </>
  );
}
