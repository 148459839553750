import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Tooltip,
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
import { CircleSlider } from 'react-circle-slider';
import { ChartNoAxesCombined } from 'lucide-react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const WinRateChart = ({ campaignWinRate, info }) => {
  return (
    <Card className="w-full max-w-md mx-auto" style={{ position: 'relative' }}>
      <Tooltip title={<Typography className="TooltipFontSize">{info}</Typography>} arrow>
        <IconButton
          aria-label="info"
          className="info-icon"
          style={{
            position: 'absolute',
            top: '8px',
            right: '8px',
            padding: '8px',
          }}
        >
          <InfoOutlinedIcon className="infoIcon" /> {/* Set color to ensure visibility */}
        </IconButton>
      </Tooltip>

      <CardHeader
        title={
          <div className="flex items-center space-x-4">
            <Typography variant="h6" align="center" className="font-weight-bold">
              <ChartNoAxesCombined className="primary-color mr-3" />
              Win Rate
            </Typography>
          </div>
        }
      />
      <div className="dotted-line font20 summaryBoxBorder custom-dotted-line"></div>
      <CardContent>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={6}>
            <div className="flex flex-col items-center">
              <div style={{ position: 'relative' }}>
                <CircleSlider
                  value={campaignWinRate?.winRate || 0}
                  size={170}
                  trackColor="#dd6633"
                  progressColor="#fcae73"
                  circleStrokeWidth={8}
                  progressWidth={8}
                  circleWidth={10}
                  knobRadius={10}
                  showTooltip={true}
                  showPercentage={true}
                  disabled={true}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="space-y-3">
              <div className="bg-green-50 p-3 rounded">
                <Typography variant="body1" className="text-green-800 font-bold">
                  <strong>App:</strong> {campaignWinRate?.appWinRate.toFixed(2) || 0}%
                </Typography>
              </div>
              <div className="bg-green-50 p-3 rounded">
                <Typography variant="body1" className="text-green-800 font-bold">
                  <strong>Web:</strong> {campaignWinRate?.webWinRate.toFixed(2) || 0}%
                </Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WinRateChart;
