import React from 'react';
import { Chart } from '../../components';
import getSessionData from '../../service-utils/session-util';
import { orderBy } from 'lodash';
import Services from '../../service-utils/services';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { CHART_COLOR } from '../../common/utils';

export const ImpressionByCategory = () => {
  const { agencyId } = getSessionData();
  const { data } = useQuery(
    ['CAMPAIGN_BY_CATEGORY', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getCampaignByCategory(queryString.stringify(payload));
      return response.data?.campaignCategorySummaryList;
    },

    {
      enabled: !!agencyId,
    },
  );
  const sortedData =
    orderBy(
      data?.map((item) => ({ ...item, name: item.ctr })),
      ['ctr'],
      ['asc'],
    ) || [];
  const Data = {
    datasets: [
      {
        fill: true,
        label: 'CTR',
        data: sortedData.map((item) => item.ctr),
        backgroundColor: CHART_COLOR.IMPRESSION,
      },
    ],
    labels: sortedData?.map((item) => item.categoryName),
  };
  const chartOptions = {
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        display: true,
        ticks: {
          autoSkip: false,
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const datasetLabel = context.dataset.label || '';
            if (datasetLabel === 'CTR') {
              const index = context.dataIndex;
              const formattedData = Data.datasets[0].data[index];
              return ` ${datasetLabel} : ${formattedData} %`;
            }
            return datasetLabel + ' : ' + data;
          },
        },
      },
    },
  };
  return <Chart type="Bar" chartData={Data} options={chartOptions} height="350px" width="500px" />;
};
