import React, { useState } from 'react';
import { NewTextField, Paper, Grid, Button, Typography, Box } from '../../common';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import getSessionData from '../../service-utils/session-util';
import { useFormik } from 'formik';
import OverlapLoader from '../../common/loader/OverlapLoader';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import { ConformationBox } from '../../components/conformationBox';
import * as Yup from 'yup';
import { Tabs, Tab } from '@material-ui/core';

const EmailSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { agencyId, tncUpdated } = getSessionData();
  const [tabValue, setTabValue] = useState(0); // State for tabs
  const [deleteId, setDeleteId] = useState();
  // Fetch emails based on agencyId
  const {
    data: agencyNotification = {},
    refetch,
    isFetching,
  } = useQuery(
    ['AGENCY_SETTING_DATA', agencyId],
    async () => {
      const response = await Services.getEmailsSetting(agencyId);
      return response?.data;
    },
    { enabled: !!agencyId },
  );

  // Mutation to add emails

  const { mutate: addEmails, isLoading } = useMutation(
    async (values) => {
      const payload = {
        email: values.email,
        agencyNotificationEnum: ['BALANCE', 'REPORT', 'INVOICE'][tabValue], // Use tabValue for notification type
      };
      const response = await Services.agencyEmailsSetting(agencyId, payload);
      return response.data;
    },
    {
      onError: (err) => {
        const errorMsg = err?.response.data.reasonDesc || 'Error in Agency settings.';
        enqueueSnackbar(errorMsg, { variant: 'error' });
      },
      onSuccess: () => {
        enqueueSnackbar('Email Added Successfully.', { variant: 'success' });
        setFieldValue('email', '');
        refetch();
      },
    },
  );

  // Mutation to remove emails
  const { mutate: removeEmail, isLoading: isRemoving } = useMutation(
    async (emailId) => {
      const response = await Services.deleteEmailSetting(agencyId, emailId);
      return response.data;
    },
    {
      onError: (err) => {
        const errorMsg = err?.response.data.reasonDesc || 'Error in deleting email.';
        enqueueSnackbar(errorMsg, { variant: 'error' });
        setDeleteId();
      },
      onSuccess: () => {
        enqueueSnackbar('Email Removed Successfully.', { variant: 'success' });
        setDeleteId();
        refetch();
      },
    },
  );

  // Formik setup
  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Must be a valid email').max(255),
    }),
    onSubmit: (values) => {
      addEmails(values);
    },
  });

  // Filter emails based on selected notification type
  const filteredEmails =
    agencyNotification?.agencyNotification?.filter(
      (setting) => setting?.agencyNotificationEnum === ['BALANCE', 'REPORT', 'INVOICE'][tabValue],
    ) || [];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Description based on selected tab
  const tabDescriptions = [
    'Email ids that will be notified on specific changes in balance.',
    'Email ids that will be notified on specific changes in reports.',
    'Email ids that will be notified on specific changes in invoices.',
  ];
  const tncLoader = tncUpdated === 'true' && isLoading;
  return (
    <div className="col-md-10 bg-white rounded shadow m-auto mb-4 p-0">
      <Paper className="pt-3 p-4 mn-h-200" style={{ display: 'flex' }}>
        <Box
          style={{
            width: '250px', // Fixed width for tabs
            borderRight: '1px solid #ddd',
            padding: '16px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={handleTabChange}
            aria-label="notification tabs"
            indicatorColor="primary"
            textColor="primary"
            TabIndicatorProps={{
              style: {
                backgroundColor: 'var(--primary)',
              },
            }}
          >
            <Tab
              label="Balance"
              style={{
                color: tabValue === 0 ? 'var(--primary)' : 'inherit',
              }}
            />
            <Tab
              label="Report"
              style={{
                color: tabValue === 1 ? 'var(--primary)' : 'inherit',
              }}
              disabled={true}
            />
            <Tab
              disabled={true}
              label="Invoice"
              style={{
                color: tabValue === 2 ? 'var(--primary)' : 'inherit',
              }}
            />
          </Tabs>
        </Box>
        <div style={{ flex: 1, padding: '16px' }}>
          <div className="d-flex justify-content-center mb-3">
            <Typography color="textPrimary" variant="h5">
              Notifications
            </Typography>
          </div>
          <div className="d-flex justify-content-center mb-3">
            <Typography>{tabDescriptions[tabValue]}</Typography>
          </div>
          <div className="">
            <Grid
              container
              spacing={3}
              className="d-flex justify-content-center align-items-center"
            >
              {filteredEmails.length === 0 && !isFetching && (
                <Typography color="textSecondary" variant="body2" className="text-center">
                  No emails found for this notification type.
                </Typography>
              )}
              {filteredEmails.map((setting) => (
                <Grid item xs="auto" key={setting.id} className="py-0 mt-3">
                  <Paper
                    className="pl-2 d-flex align-items-center"
                    style={{ borderRadius: '25px', backgroundColor: '#FFF4F0' }}
                  >
                    <Typography
                      className="pl-2 d-flex justify-content-center"
                      color="textPrimary"
                      variant="body1"
                      style={{ flexGrow: 1 }}
                    >
                      {setting.email}
                    </Typography>
                    <IconButton
                      onClick={() => setDeleteId(setting.id)}
                      color="primary"
                      disabled={isRemoving}
                    >
                      <HighlightOffIcon
                        style={{ width: '15px', height: '16px', color: '#fcae73' }}
                      />
                    </IconButton>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </div>

          <div className="mt-4">
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3} className="py-0 mt-3"></Grid>
                <Grid item xs={12} sm={6} className="py-0">
                  <NewTextField
                    required
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email id"
                    margin="normal"
                    name="email"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('email', event.target.value)}
                    value={values.email}
                    variant="outlined"
                    type="text"
                  />
                </Grid>
                <Grid item xs={12} sm={3} className="py-0 mt-3">
                  <Button
                    variant="contained"
                    type="submit"
                    className="btn btn-primary d-flex ml-4"
                    disabled={tncLoader}
                  >
                    {tncLoader ? 'Adding...' : 'Add'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
          {tncLoader && <OverlapLoader />}
        </div>
      </Paper>
      <ConformationBox
        isOpen={!!deleteId}
        handleClose={() => setDeleteId(null)}
        handleSubmit={() => removeEmail(deleteId)}
        title="Delete ?"
        subtitle="Are you sure you want to delete this Email Id?"
        btnCloseLabel="Close"
        btnSubmitLabel="Delete"
      />
    </div>
  );
};
export default EmailSettings;
