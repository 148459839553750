/* eslint-disable react/display-name */
import React from 'react';
import { Link } from 'react-router-dom';
import './footer.scss';

const Footer = React.memo(() => {
  return (
    <>
      <footer className="footer d-flex align-items-center justify-content-center">
        <div className="d-flex flex-wrap align-items-center justify-content-center">
          <Link
            className="footer-link"
            to={{ pathname: 'https://www.adzylytics.com/privacy-policy/' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          <Link
            className="footer-link mx-3"
            to={{ pathname: 'https://www.adzylytics.com/terms-and-conditions/' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </Link>
          <Link
            className="footer-link "
            to={{ pathname: 'https://www.adzylytics.com/adzylytics-ads-policy/' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Ads Policy
          </Link>
          <Link
            className="footer-link "
            to={{ pathname: 'https://www.adzylytics.com/refund-and-cancellation/' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Refund Policy
          </Link>
          <p className="footer-link mx-3 mb-0">
            &copy; {new Date().getFullYear()} Adzylytics. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
});

export default Footer;
