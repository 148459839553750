import React, { useMemo } from 'react';
import { Chart } from '../../components';
import { orderBy } from 'lodash';
import Services from '../../service-utils/services';
import getSessionData from '../../service-utils/session-util';
import { useQuery } from 'react-query';
import { getimpressionsData } from '../../common/utils';
import { getspendsData } from '../../common/utils';
import { getclickCountsData } from '../../common/utils';
import { externalTooltipHandler } from '../../components/chart';
import { CHART_COLOR, getCurrencySymbol } from '../../common/utils';
import { useAdvertiser } from '../../context/AdvertiserProvider';
//import { CACHE_TIME, STALE_TIME } from './../../common/constants';

export const CampaignImpressionChartByAdv = (props) => {
  const { agencyId } = getSessionData();
  const { advertiserId } = useAdvertiser();

  const { data } = useQuery(
    ['CAMPAIGN_IMPRESSION_CHART_BY_ADV', agencyId, advertiserId],
    async () => {
      const response = await Services.getCampaignWiseImpressionsByAdv(agencyId, advertiserId);
      return response.data?.graphDataList;
    },
    {
      enabled: !!agencyId,
      //staleTime: STALE_TIME.HALF_HOUR,
      //cacheTime: CACHE_TIME.HALF_HOUR,
    },
  );

  // let sortedData = [];

  // if (data && Array.isArray(data)) {
  //   sortedData = orderBy(
  //     data
  //       .filter((item) => item && item.impressionCount != null) // Filter out null or undefined items
  //       .map((item) => ({ ...item, y: item.impressionCount })),
  //     ['impressionCount', 'label'],
  //     ['desc', 'asc'],
  //   );
  // }

  const Data = useMemo(() => {
    const sortedData =
      orderBy(
        data?.map((item) => ({
          ...item,
          y: item.value,
        })),
        ['value', 'label'],
        ['desc', 'asc'],
      ) || [];

    const impressions = sortedData.map((item) => item.value);
    let impressionData = getimpressionsData(impressions);

    const spends = sortedData.map((item) => item.totalCost.amount);
    let spendData = getspendsData(spends);
    // console.log(spendData);

    const clickCounts = sortedData.map((item) => item.clickCount);
    let clickData = getclickCountsData(clickCounts);

    return {
      labels: sortedData?.map((item) => item.label),
      datasets: [
        {
          fill: false,
          label: 'Spend',
          data: spendData,
          actualData: spends,
          backgroundColor: CHART_COLOR.SPEND,
          borderColor: CHART_COLOR.SPEND,
          pointRadius: 0,
          borderWidth: 2,
          type: 'line',
        },
        {
          fill: true,
          label: 'Impressions',
          data: impressionData,
          actualData: impressions,
          backgroundColor: CHART_COLOR.IMPRESSION,
          pointRadius: 0,
          borderWidth: 2,
        },
        {
          fill: true,
          label: 'Click Count',
          data: clickData,
          actualData: clickCounts,
          backgroundColor: CHART_COLOR.CLICK_COUNT,
          pointRadius: 0,
          borderWidth: 2,
        },
      ],
    };
  }, [data]);

  let currencyData;
  if (data) {
    const currency = data.map((item) => item.totalCost.currency);
    currencyData = getCurrencySymbol(currency[0]);
  }

  const options = {
    interaction: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: (tooltipItem = {}, data) => {
          const {
            dataset: { label = '', actualData = [] },
            dataIndex,
          } = tooltipItem || {};

          let tooltipText = `${label} : ${actualData[dataIndex]}`;

          if (label === 'Spend') {
            tooltipText = `${label} : ${currencyData} ${actualData[dataIndex]}`;
          }

          return tooltipText;
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler,
      },
    },
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Percentile',
        },
      },
    },
  };

  return (
    <div>
      <Chart type="Bar" chartData={Data} options={options} />
    </div>
  );
};
