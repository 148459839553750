import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import queryString from 'query-string';
import { setHeader } from '../../libs/redux-sdk/actions';
import Services from '../../service-utils/services';
import getSessionData from './../../service-utils/session-util';
import { Table, SearchInput } from '../../components';
import { Button, Loader, Typography } from './../../common';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import CreateIcon from '../../components/create-icon/createIcon';
import { ResultButton, SearchButton, ViewButton } from '../../components/react-icons/ReactIcons';
export const Conversion = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [searchText, setSearchText] = useState('');
  const queryParams = queryString.parse(location.search);
  const { agencyId } = getSessionData();

  // Dialog state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [urlInput, setUrlInput] = useState('');

  const { data, isLoading, refetch } = useQuery(
    ['CONVERSION_DATA', pageLimit, pageNumber, agencyId, queryParams.name],
    async () => {
      const queryStringParams = {
        pageNum: pageNumber - 1,
        pageSize: pageLimit,
        agencyId,
        name: queryParams.name,
      };
      const response = await Services.conversionSearch(queryString.stringify(queryStringParams));
      return response?.data;
    },
    { enabled: !!agencyId },
  );

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Conversion
          </div>
        ),
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    const parsedPageNum = Number(queryParams.pageNum);
    if (!isNaN(parsedPageNum) && parsedPageNum + 1 !== pageNumber) {
      setPageNumber(parsedPageNum + 1);
    }
    setPageLimit(Number(queryParams.pageSize) || 10);
  }, [location.search]);

  const handleApply = useCallback(() => {
    const updatedParams = {
      ...queryParams,
      name: searchText,
      pageNum: 0, // Reset to first page when applying filters
      pageSize: pageLimit,
    };
    history.replace({ search: queryString.stringify(updatedParams) });
    setPageNumber(1);
    refetch();
  }, [searchText, pageLimit, history, queryParams, refetch]);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    const updatedParams = {
      ...queryString.parse(location.search),
      pageNum: page - 1, // adjust for 0-based index
      pageSize: sizePerPage,
    };
    history.replace({ search: queryString.stringify(updatedParams) });
  };

  const handleEditViewConversion = (id) => {
    history.push(`/conversion/${id}`);
  };
  const handleEditResult = (row) => {
    history.push(
      `/conversion/result/${row.id}?conversionCode=${row.code}&conversionName=${row.name}`,
    );
  };
  // const manageButton = (row) => (
  //   <Dropdown id="dropdown-item-button" className="action-dropdown btn-action ">
  //     <Dropdown.Toggle variant="link">
  //       <MoreHorizIcon />
  //     </Dropdown.Toggle>
  //     <Dropdown.Menu className="shadow-lg">
  //       <Dropdown.Item onClick={() => history.push(`/conversion/${row.id}`)}>View</Dropdown.Item>
  //       <Dropdown.Item
  //         onClick={() =>
  //           history.push(
  //             `/conversion/result/${row.id}?conversionCode=${row.code}&conversionName=${row.name}`,
  //           )
  //         }
  //       >
  //         Results
  //       </Dropdown.Item>
  //     </Dropdown.Menu>
  //   </Dropdown>
  // );

  const handleDialogClose = () => {
    setDialogOpen(false);
    setUrlInput('');
  };

  const handleSubmitUrl = () => {
    window.open(urlInput, '_blank');
    handleDialogClose();
  };

  const handleIcons = (row) => {
    const { id } = row;
    return (
      <div className="flex items-center gap-2">
        <ViewButton handleView={() => handleEditViewConversion(id)} />
        <ResultButton handleView={() => handleEditResult(row)} />
      </div>
    );
  };
  const getTableHeader = [
    {
      dataField: 'name',
      text: 'Conversion Name',
      formatter: (col, row) => <a href={`/conversion/${row.id}`}>{col}</a>,
    },
    {
      dataField: 'code',
      text: 'Conversion Code',
    },
    // {
    //   dataField: '',
    //   text: '',
    //   formatter: (col, row) => (
    //     <Link
    //       className="link primary-color"
    //       onClick={handleSetCookie} // Update to call handleSetCookie
    //     >
    //       Set Cookie
    //     </Link>
    //   ),
    // },
    // {
    //   dataField: '',
    //   text: '',
    //   formatter: (col, row) => (
    //     <Link className="link primary-color" onClick={() => handleTestRunClick(row.id)}>
    //       Test Run
    //     </Link>
    //   ),
    // },

    // {
    //   dataField: 'id',
    //   text: '',
    //   formatter: (col, row) => manageButton(row),
    // },
    {
      dataField: 'id',
      text: '',
      formatter: (col, row) => handleIcons(row),
    },
  ];
  const handleButtonClick = () => {
    history.push('/conversion-create');
  };

  return (
    <>
      <div className="mb-3 d-flex justify-content-between filters">
        <div className="d-flex">
          <SearchInput onChange={(e) => setSearchText(e.target.value)} value={searchText} />
          <Button
            variant="contained"
            className="btn btn-primary d-flex btn-38 ml-3"
            onClick={handleApply}
            startIcon={<SearchButton />}
          >
            Apply
          </Button>
        </div>
        {/* <Button
          variant="contained"
          className="btn btn-primary d-flex btn-add"
          onClick={() => history.push(`/conversion-create`)}
        >
          New Conversion
        </Button> */}
        <div>
          <CreateIcon label="Create New Conversion" handleAdd={handleButtonClick} />
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          keyField="id"
          tableData={data?.conversionsList || []}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={data?.totalElements}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible"
          defaultSorted={[
            {
              dataField: 'firstName',
              order: 'asc',
            },
          ]}
          additionalText="To get started, set up conversion tracking to count the number of conversions."
          onButtonClick={handleButtonClick}
          buttonLabel="Click here to new conversion"
        />
      )}
      {/* {cookieValue && <img src={cookieValue} height="0px" width="0px" border="0px"></img>} */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        PaperProps={{
          style: {
            width: '600px', // Set your desired width
            height: '300px', // Set your desired height
          },
        }}
      >
        <DialogTitle>Test Run</DialogTitle>

        <DialogContent>
          <Typography className="mb-3">* Please put your thank you page url.</Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Enter URL"
            type="url"
            fullWidth
            variant="outlined"
            value={urlInput}
            onChange={(e) => setUrlInput(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} className="btn btn-secondary d-flex">
            Cancel
          </Button>
          <Button
            onClick={handleSubmitUrl}
            className="btn btn btn-primary d-flex"
            disabled={!urlInput}
          >
            Run
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
