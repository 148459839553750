import React, { Suspense, lazy, useState } from 'react';
// import happy_color from './../../../assets/img/happyColor.jpg';
// import tablet_frame from './../../../assets/img/tablet_frame.png';
import desktop from './../../../assets/img/desktop.png';
import weather from './../../../assets/img/weather.png';
// import smartphoneicon from './../../../assets/icon/smartphoneicon.png';
// import desktopicon from './../../../assets/icon/desktopicon.png';
// import tableticon from './../../../assets/icon/tableticon.png';
const LazyImage = lazy(() => import('./LazyImage')); // Import LazyImage component
const BANNER_728_90 = ({ imageUrl = '' }) => {
  const [currentView, setCurrentView] = useState('desktop');

  const handleViewChange = (view) => {
    setCurrentView(view);
  };
  return (
    <>
      {/* <div className="view-buttons"> */}
      {/* <button
          className={`btn1 d-flex1 ${currentView === 'mobile' ? 'selected' : ''}`}
          onClick={() => handleViewChange('mobile')}
        >
          <img src={smartphoneicon} alt="" className="icons" />
        </button> */}
      {/* <button
          className={`btn1 d-flex1 ${currentView === 'desktop' ? 'selected' : ''}`}
          onClick={() => handleViewChange('desktop')}
        >
          <img src={desktopicon} alt="" className="icons" />
        </button>
        <button
          className={`btn1 d-flex1 ${currentView === 'tablet' ? 'selected' : ''}`}
          onClick={() => handleViewChange('tablet')}
        >
          <img src={tableticon} alt="" className="icons" />
        </button>
      </div> */}
      {/* {tablet_frame && currentView === 'tablet' && (
        <div className="BANNER_728_90 tablet_app_wrapper">
          <img src={happy_color} alt="banner" className="app_image" />
          <img src={tablet_frame} alt="tablet" className="frame_image" />
          <Suspense>
            <LazyImage imageUrl={imageUrl} />
          </Suspense>
        </div>
      )} */}
      {desktop && currentView === 'desktop' && (
        <div className="BANNER_728_90 desktop_app_wrapper">
          <img src={weather} alt="banner" className="app_image1" />
          <img src={desktop} alt="desktop" className="frame_image w-100" />
          <Suspense>
            <LazyImage imageUrl={imageUrl} className="case2" />
          </Suspense>
        </div>
      )}
    </>
  );
};

export default BANNER_728_90;
