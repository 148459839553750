import getSessionData from './session-util';
import axios from 'axios';
import { BASE_URL } from './../common/constants';
import ReactDOM from 'react-dom/client';
import TncConsentPopup from '../components/TncConsentPopup/TncConsentPopup';
import { QueryClient, QueryClientProvider } from 'react-query';
const queryClient = new QueryClient();

const showTncPopup = () =>
  new Promise((resolve, reject) => {
    const modalRoot = document.createElement('div');
    document.body.appendChild(modalRoot);
    const root = ReactDOM.createRoot(modalRoot);
    root.render(
      <QueryClientProvider client={queryClient}>
        <TncConsentPopup />
      </QueryClientProvider>,
    );
  });

const httpService = async (
  serviceUrl,
  type = 'get',
  data,
  service = 'dsp-api',
  multipart = false,
  additionalHeaders = {},
  stream = false,
  additionalConfig = {},
) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
    },
    ...additionalConfig,
  };
  if (additionalHeaders) {
    config.headers = { ...config.headers, ...additionalHeaders };
  }
  if (multipart) config.headers['Content-Type'] = 'multipart/form-data';
  if (stream) config['responseType'] = 'blob';
  let { accessToken, tncUpdated } = getSessionData();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }
  const isPublicOrTncDateUpdate =
    serviceUrl.includes('auth') ||
    serviceUrl.includes('public') ||
    serviceUrl.includes('/tncDateUpdate');

  if (
    !isPublicOrTncDateUpdate &&
    ['post', 'put', 'patch', 'delete'].includes(type.toLowerCase()) &&
    tncUpdated !== 'true'
  ) {
    try {
      await showTncPopup();
    } catch (error) {
      return Promise.reject(error); // Reject if TNC not accepted
    }
  }
  /*
  if (agencyId) {
    config.headers['X-AgencyId'] = agencyId;
  } else {
    config.headers['X-AgencyId'] = 'master';
  }
  */

  config.cancelToken = source.token;

  let url = `${BASE_URL}/${service}/${serviceUrl}`;

  switch (type) {
    case 'get': {
      const promise = axios.get(url, config);
      promise.cancel = () => {
        source.cancel('Query was cancelled by React Query');
      };
      return promise;
    }
    case 'post':
    case 'put':
    case 'patch':
      return axios[type](url, data, config);
    case 'delete':
      if (data) config.data = data;
      return axios.delete(url, config);
    default: {
      break;
    }
  }
  return null;
};

export default httpService;
