import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // For form validation
import { NewTextField, Grid, Button, NewDatePicker } from '../../common';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { useMutation } from 'react-query';
import Services from '../../service-utils/services';
import { getEpochDate } from '../../components/format-date-moment';
import { VOUCHER_STATUS } from '../../libs/constants';
import history from '../../history';
import { useSnackbar } from 'notistack';
import { getCurrencySymbol, handleMsgOnForm } from '../../common/utils';
import moment from 'moment';
import { ConformationBox } from '../../components/conformationBox';

const CreateVoucher = ({ currency, userAgencyId, agencyName, handleBackButton }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});
  const [showConfirmation, setShowConfirmation] = useState(false); // State to show confirmation
  const [pendingValues, setPendingValues] = useState(null); // Store pending form values

  const { mutate: createVoucher, isLoading: isLoadingCreateVoucher } = useMutation(
    async (values) => {
      const { voucherCode, validity, amount } = values;
      const payload = {
        voucherCode,
        amount: parseInt(amount),
        validity: getEpochDate(validity, 'yyyy/MM/dd HH:mm'),
        currency: currency,
        status: 'ACTIVE',
      };
      const response = await Services.agencyCreateVoucher(userAgencyId, payload);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: (data) => {
        history.push(`/agency-billing/account?agencyId=${userAgencyId}&agencyName=${agencyName}#2`);
        enqueueSnackbar('Voucher Created successfully.', { variant: 'success' });
        handleBackButton();
      },
    },
  );
  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      voucherCode: '',
      amount: '',
      validity: moment().add(30, 'days'),
      status: VOUCHER_STATUS[0],
    },
    validationSchema: Yup.object({
      validity: Yup.date().required('Start date is required').nullable(),
      amount: Yup.number().required('Amount is required').positive('Amount must be greater than 0'),
    }),
    onSubmit: (values) => {
      setPendingValues(values); // Store form values temporarily
      setShowConfirmation(true); // Show confirmation box
    },
  });

  useEffect(() => {
    setServerErrors({});
  }, [values]);

  const handleConfirmSubmit = () => {
    createVoucher(pendingValues); // Create voucher with the stored values
    setShowConfirmation(false); // Close confirmation box
  };

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="p-2">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <NewTextField
                variant="outlined"
                label="Voucher Code (Optional)"
                margin="normal"
                fullWidth
                name="voucherCode"
                value={values.voucherCode}
                onChange={(event) => setFieldValue('voucherCode', event.target.value)}
                onBlur={handleBlur}
                error={touched.voucherCode && !!errors.voucherCode}
                helperText={touched.voucherCode && errors.voucherCode}
              />
            </Grid>
            <Grid item xs={12}>
              <NewTextField
                required
                variant="outlined"
                label="Enter Amount"
                margin="normal"
                type="number"
                fullWidth
                name="amount"
                value={values.amount}
                onChange={(event) => setFieldValue('amount', event.target.value)}
                onBlur={handleBlur}
                error={touched.amount && !!errors.amount}
                helperText={touched.amount && errors.amount}
              />
            </Grid>
            {/* <Grid item xs={12} className="py-0">
              <NewSelect
                required
                options={VOUCHER_STATUS}
                value={values.status}
                onChange={(obj) => {
                  setFieldValue('status', obj);
                }}
                placeholder={`Status`}
                className="py-2"
              />
              {serverErrors.enabled && <p className="text-danger mt-n2">{serverErrors.enabled}</p>}
            </Grid> */}
            <Grid item xs={12} className="py-0">
              <NewDatePicker
                required
                placeholder="Validity Date"
                initialValue={values.validity}
                timePicker
                onCallback={(date) => setFieldValue('validity', date)}
                key={JSON.stringify({ val: values.validity })}
                minDate={new Date()}
              />
              {touched.validity && errors.validity && (
                <p className="text-danger mt-n2">{errors.validity}</p>
              )}
            </Grid>
          </Grid>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <Button
              type="submit"
              className="btn btn-primary mt-2 w-40 button-container"
              size="medium"
            >
              {isLoadingCreateVoucher ? 'Creating...' : 'Create'}
            </Button>
          </div>
        </form>
        {isLoadingCreateVoucher && <OverlapLoader />}
      </div>
      <ConformationBox
        isOpen={showConfirmation}
        handleClose={() => setShowConfirmation(false)}
        handleSubmit={handleConfirmSubmit}
        title={`Voucher Amount :- ${getCurrencySymbol(currency)} ${values.amount}`}
        subtitle="Are you sure you want to create this voucher?"
        btnCloseLabel="Cancel"
        btnSubmitLabel="Create"
      />
    </div>
  );
};

export default CreateVoucher;
