import React, { useEffect } from 'react';
import { NewTextField, Paper, Grid, Button, Typography } from '../../common';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import getSessionData from '../../service-utils/session-util';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import OverlapLoader from '../../common/loader/OverlapLoader';
export const BillingDetails = () => {
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();
  let { agencyId, tncUpdated } = getSessionData();

  const { data: agencyData = {} } = useQuery(
    ['AGENCY_DATA', agencyId],
    async () => {
      const response = await Services.getAgency(agencyId);

      return response?.data?.agencyDto;
    },
    { enabled: !!agencyId },
  );

  const { mutate: updateAgency, isLoading } = useMutation(
    async (values) => {
      const payload = {
        ...agencyData,
        ...values,
      };
      const response = await Services.agencyUpdate(agencyId, payload);
      return response.data;
    },
    {
      onError: (err) => {
        let errorMsg = err?.response.data.reasonDesc || 'Error in updating profile.';
        enqueueSnackbar(errorMsg, { variant: 'error' });
      },
      onSuccess: (data) => {
        enqueueSnackbar('Billing Details updated successfully.', { variant: 'success' });
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      agencyName: '', // Adjusted field name
      gstNumber: '',
    },
    // validationSchema: ...
    onSubmit: () => updateAgency(values),
  });

  useEffect(() => {
    if (!isEmpty(agencyData)) {
      setFieldValue('agencyName', agencyData.agencyName);
      setFieldValue('gstNumber', agencyData.gstNumber);
      setFieldValue('billAddress1', agencyData.billAddress1);
      setFieldValue('billAddress2', agencyData.billAddress2);
      setFieldValue('billCity', agencyData.billCity);
      setFieldValue('billState', agencyData.billState);
      setFieldValue('billZip', agencyData.billZip);
      setFieldValue('country', agencyData.country);
    }
  }, [agencyData]);
  const tncLoader = tncUpdated === 'true' && isLoading;
  return (
    <>
      <div className="col-md-10 bg-white rounded shadow m-auto mb-4 p-0">
        <Paper className="pt-3 p-4 mn-h-200">
          <div className="d-flex justify-content-center mb-3">
            <Typography color="textPrimary" variant="h5">
              Billing Details
            </Typography>
          </div>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} className="py-0">
                <NewTextField
                  required
                  info="Enter your comapny name"
                  error={Boolean(touched.agencyName && errors.agencyName)}
                  fullWidth
                  helperText={touched.agencyName && errors.agencyName}
                  label=" Company Name"
                  margin="normal"
                  name="agencyName"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('agencyName', event.target.value)}
                  value={values.agencyName}
                  variant="outlined"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="py-0">
                <NewTextField
                  error={Boolean(touched.gstNumber && errors.gstNumber)}
                  fullWidth
                  info="Enter GST No if its indian business"
                  helperText={touched.gstNumber && errors.gstNumber}
                  label="GST No"
                  margin="normal"
                  name="gstNumber"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('gstNumber', event.target.value)}
                  value={values.gstNumber}
                  variant="outlined"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="py-0">
                <NewTextField
                  required
                  info="Enter valid billing address. e.g  corporate office address"
                  error={Boolean(touched.billAddress1 && errors.billAddress1)}
                  fullWidth
                  helperText={touched.billAddress1 && errors.billAddress1}
                  label="Billing Address1"
                  margin="normal"
                  name="billAddress1"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('billAddress1', event.target.value)}
                  value={values.billAddress1}
                  variant="outlined"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="py-0">
                <NewTextField
                  required
                  info="Enter full address"
                  error={Boolean(touched.billAddress2 && errors.billAddress2)}
                  fullWidth
                  helperText={touched.billAddress2 && errors.billAddress2}
                  label="Billing Address2"
                  margin="normal"
                  name="billAddress2"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('billAddress2', event.target.value)}
                  value={values.billAddress2}
                  variant="outlined"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="py-0">
                <NewTextField
                  required
                  info="Enter city name"
                  error={Boolean(touched.billCity && errors.billCity)}
                  fullWidth
                  helperText={touched.billCity && errors.billCity}
                  label="Billing City"
                  margin="normal"
                  name="billCity"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('billCity', event.target.value)}
                  value={values.billCity}
                  variant="outlined"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="py-0">
                <NewTextField
                  required
                  info="Enter valid zip code"
                  error={Boolean(touched.billZip && errors.billZip)}
                  fullWidth
                  helperText={touched.billZip && errors.billZip}
                  label="Billing Zip"
                  margin="normal"
                  name="billZip"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('billZip', event.target.value)}
                  value={values.billZip}
                  variant="outlined"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="py-0">
                <NewTextField
                  required
                  info="Select valid state"
                  error={Boolean(touched.billState && errors.billState)}
                  fullWidth
                  helperText={touched.billState && errors.billState}
                  label="Billing State"
                  margin="normal"
                  name="billState"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('billState', event.target.value)}
                  value={values.billState}
                  variant="outlined"
                  type="text"
                />
              </Grid>

              <Grid item xs={12} sm={6} className="py-0">
                <NewTextField
                  required
                  info="Select valid country"
                  error={Boolean(touched.country && errors.country)}
                  fullWidth
                  helperText={touched.country && errors.country}
                  label="Billing Country"
                  margin="normal"
                  name="country"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('country', event.target.value)}
                  value={values.country}
                  variant="outlined"
                  type="text"
                />
              </Grid>
            </Grid>
            <div className="mt-4 d-flex justify-content-end ">
              <Button variant="contained" class="btn btn-secondary d-flex" onClick={history.goBack}>
                Cancel
              </Button>
              <Button variant="contained" type="submit" class="btn btn-primary d-flex ml-4">
                {tncLoader ? 'Updating' : 'Update'}
              </Button>
              {tncLoader && <OverlapLoader />}
            </div>
          </form>
        </Paper>
      </div>
    </>
  );
};
