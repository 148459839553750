import queryString from 'query-string';
import { useQuery } from 'react-query';
import Services from './../services';
import { CACHE_TIME, STALE_TIME } from './../../common/constants';
export const GetCampaignData = (advertiserId, campaignId, agencyId, agencyCurrency) => {
  // let { advertiserId } = getSessionData();
  return useQuery(
    ['CAMPAIGN_DATA', advertiserId, campaignId, agencyId],
    async () => {
      const payload = {
        agencyId,
        currency: agencyCurrency,
      };
      const response = await Services.campaignGetDetails(
        advertiserId,
        campaignId,
        queryString.stringify(payload),
      );
      return response.data?.campaignDTO;
    },
    {
      enabled: !!campaignId && !!agencyId,
      staleTime: STALE_TIME.FIVE_MIN,
      cacheTime: CACHE_TIME.FIVE_MIN,
    },
  );
};
