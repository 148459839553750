import React from 'react';
import { Chart } from '../../components';
import { orderBy } from 'lodash';
import Services from '../../service-utils/services';
import getSessionData from '../../service-utils/session-util';
import { useQuery } from 'react-query';
import '../dashboard';
//import { CACHE_TIME, STALE_TIME } from './../../common/constants';
import { CHART_COLORS_LIST } from '../../common/utils';
import { useAdvertiser } from '../../context/AdvertiserProvider';

export const DeviceTypeImpressionChartByAdv = (props) => {
  const { agencyId } = getSessionData();
  const { advertiserId } = useAdvertiser();

  const { data } = useQuery(
    ['DEVICE_IMPRESSION_CHART_BY_ADV', agencyId, advertiserId],
    async () => {
      const response = await Services.getDeviceWiseImpressionsByAdv(agencyId, advertiserId);
      return response.data?.graphDataList;
    },
    {
      enabled: !!agencyId,
      //staleTime: STALE_TIME.HALF_HOUR,
      //cacheTime: CACHE_TIME.HALF_HOUR,
    },
  );

  const sortedData =
    orderBy(
      data?.map((item) => ({ ...item, name: item.label, y: item.value })),
      ['label'],
      ['asc'],
    ) || [];
  const Data = {
    datasets: [
      {
        fill: false,
        label: 'Impressions',
        data: sortedData.map((item) => item.value),
        backgroundColor: CHART_COLORS_LIST,
        innerHeight: 200,
      },
    ],
    labels: sortedData?.map((item) => item.label),
  };

  return (
    <div className="pie-chart-container">
      <Chart type="Pie" chartData={Data} />
    </div>
  );
};
