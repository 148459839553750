import React, { useRef, useState, useCallback, useEffect } from 'react';
import { NewTextField, Paper, Grid, Button, Typography } from '../../common';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import getSessionData from '../../service-utils/session-util';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { CONTENT_URL } from '../../common/constants';
import { UploadImage } from '../../components/uploadFile/upload-img';

export const AgencyUpdate = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { agencyId, tncUpdated } = getSessionData();
  const inputFile = useRef(null);
  const [logoUrl, setLogoUrl] = useState('');
  const [fileUploadDesc, setFileUploadDesc] = useState('Upload your agency logo here.');
  const [file, setFile] = useState(null);

  const { data: agencyData = {}, refetch } = useQuery(
    ['AGENCY_DATA', agencyId],
    async () => {
      const response = await Services.getAgency(agencyId);
      return response?.data?.agencyDto;
    },
    { enabled: !!agencyId },
  );

  const { mutate: updateAgency, isLoading } = useMutation(
    async (values) => {
      const payload = {
        ...agencyData,
        ...values,
      };
      const response = await Services.agencyUpdate(agencyId, payload);
      return response.data;
    },
    {
      onError: (err) => {
        const errorMsg = err?.response?.data?.reasonDesc || 'Error updating profile.';
        enqueueSnackbar(errorMsg, { variant: 'error' });
      },
      onSuccess: (data) => {
        enqueueSnackbar('Contact Details updated successfully.', { variant: 'success' });
      },
    },
  );

  const { mutate: deleteLogo } = useMutation(
    async () => {
      await Services.deleteAgencyLogo(agencyId); // Implement this API call in your Services
      enqueueSnackbar('Logo deleted successfully.', { variant: 'success' });
      setLogoUrl('');
      refetch();
    },
    {
      onError: (err) => {
        const errorMsg = err?.response?.data?.reasonDesc || 'Error deleting logo.';
        enqueueSnackbar(errorMsg, { variant: 'error' });
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      contactEmail: '',
      contactName: '',
      contactNo: '',
      currency: '',
      agencyType: '',
    },
    onSubmit: (values) => updateAgency(values),
  });

  useEffect(() => {
    if (!isEmpty(agencyData)) {
      setFieldValue('contactEmail', agencyData.contactEmail);
      setFieldValue('contactName', agencyData.contactName);
      setFieldValue('contactNo', agencyData.contactNo);
      setFieldValue('currency', agencyData.currency);
      setFieldValue('agencyType', agencyData.agencyType);
      setLogoUrl(`${CONTENT_URL}/${agencyData.logoUrl}`);
    }
  }, [agencyData, setFieldValue]);

  const checkImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        if (img.width <= 150 && img.height <= 40) {
          resolve(true);
        } else {
          reject(new Error('Invalid logo dimensions. Maximum size allowed is 150 x 40 pixels.'));
        }
      };
      img.onerror = () => reject(new Error('Failed to load image.'));
      img.src = URL.createObjectURL(file);
    });
  };
  const handleImageUpload = useCallback(
    async (file) => {
      if (file) {
        try {
          // setIsUploading(true);
          await checkImageDimensions(file);

          let formData = new FormData();
          formData.append('file', file);
          const { data } = await Services.agencyLogo(agencyId, formData);
          enqueueSnackbar('Logo uploaded successfully.', { variant: 'success' });
          setLogoUrl(URL.createObjectURL(file));
          refetch();
        } catch (err) {
          const errorMsg =
            err.message || err?.response?.data?.reasonDesc || 'Error uploading logo.';
          enqueueSnackbar(errorMsg, { variant: 'error' });
        } finally {
          // setIsUploading(false);
        }
      }
    },
    [agencyId, enqueueSnackbar, refetch],
  );

  useEffect(() => {
    if (file) {
      handleImageUpload(file);
    }
  }, [file, handleImageUpload]);
  const tncLoader = tncUpdated === 'true' && isLoading;
  return (
    <>
      <div className="col-md-10 bg-white rounded shadow m-auto mb-4 p-0">
        <Paper className="pt-3 p-4 mn-h-200">
          <div className="d-flex justify-content-center mb-3">
            <Typography color="textPrimary" variant="h5">
              Agency Details
            </Typography>
          </div>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} className="py-0">
                <NewTextField
                  required
                  info="Enter contact name to create account"
                  error={Boolean(touched.contactName && errors.contactName)}
                  fullWidth
                  helperText={touched.contactName && errors.contactName}
                  label="Contact Name"
                  margin="normal"
                  name="contactName"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('contactName', event.target.value)}
                  value={values.contactName}
                  variant="outlined"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="py-0">
                <NewTextField
                  required
                  info="Enter valid contact number"
                  error={Boolean(touched.contactNo && errors.contactNo)}
                  fullWidth
                  helperText={touched.contactNo && errors.contactNo}
                  label="Contact Number"
                  margin="normal"
                  name="contactNo"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('contactNo', event.target.value)}
                  value={values.contactNo}
                  variant="outlined"
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="py-0">
                <NewTextField
                  required
                  info="Enter valid email id which is working"
                  error={Boolean(touched.contactEmail && errors.contactEmail)}
                  fullWidth
                  helperText={touched.contactEmail && errors.contactEmail}
                  label="Contact Email"
                  margin="normal"
                  name="contactEmail"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('contactEmail', event.target.value)}
                  value={values.contactEmail}
                  variant="outlined"
                  disabled
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="py-0">
                <NewTextField
                  required
                  info="Select company type whether it is Agency, Brand etc"
                  error={Boolean(touched.agencyType && errors.agencyType)}
                  fullWidth
                  helperText={touched.agencyType && errors.agencyType}
                  label="Company Type"
                  margin="normal"
                  name="agencyType"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('agencyType', event.target.value)}
                  value={values.agencyType}
                  variant="outlined"
                  disabled
                  type="text"
                />
              </Grid>
              <Grid item xs={12} sm={6} className="py-0">
                <NewTextField
                  required
                  info="Select valid currency for billing"
                  error={Boolean(touched.currency && errors.currency)}
                  fullWidth
                  helperText={touched.currency && errors.currency}
                  label="Currency"
                  margin="normal"
                  name="currency"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('currency', event.target.value)}
                  value={values.currency}
                  variant="outlined"
                  disabled
                  type="text"
                />
              </Grid>
            </Grid>
            <div className="mt-4 d-flex justify-content-end">
              <Button
                variant="contained"
                className="btn btn-secondary d-flex"
                onClick={history.goBack}
              >
                Cancel
              </Button>
              <Button variant="contained" type="submit" className="btn btn-primary d-flex ml-4">
                {tncLoader ? 'Updating' : 'Update'}
              </Button>
              {tncLoader && <OverlapLoader />}
            </div>
          </form>
        </Paper>
      </div>
      <div className="col-md-10 bg-white rounded shadow m-auto mb-4 p-0">
        <Paper className="pt-3 p-4 mn-h-200">
          <div className="d-flex justify-content-center">
            <Typography color="textPrimary" variant="h6">
              {agencyData?.logoUrl ? 'Update Agency Logo' : 'Upload Agency Logo'}
            </Typography>
          </div>
          <Grid container spacing={3} className="d-flex justify-content-center">
            <Grid item xs={12} className="d-flex justify-content-center mt-4">
              <Grid container spacing={3}>
                <Grid item xs={12} className="py-0 mt-2">
                  <UploadImage
                    accept="image/png, image/jpeg, image/jpg"
                    desc={fileUploadDesc}
                    acceptedFileTypes={['png', 'jpeg', 'jpg']}
                    getImage={(file) => setFile(file.file_format)}
                    inputFileRef={inputFile}
                    agencyData={agencyData}
                    refetch={refetch}
                    deleteLogo={deleteLogo}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className="d-flex justify-content-center mt-4">
            <Typography>
              Note: Allowed formats are .jpg, .jpeg, and .png. Maximum size allowed is 150 x 40
              pixels.
            </Typography>
          </div>
          {tncLoader && <OverlapLoader />}
        </Paper>
      </div>
    </>
  );
};
