import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Paper, Grid, Button, Typography, NewSelect } from '../../common';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import history from '../../history';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import getSessionData from '../../service-utils/session-util';
import { handleMsgOnForm } from '../../common/utils';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { SGEMENT_STATUS } from '../../libs/constants';

const IpSegmentCreate = () => {
  const { ipSegmentId } = useParams();
  const { advertiserId } = useAdvertiser();
  let { agencyId, tncUpdated } = getSessionData();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          link: '/advertiser/ip-segment',
        },
      }),
    );
  }, [dispatch]);

  const { data: ipAddressData = {} } = useQuery(
    ['ipAddressDATA', advertiserId, ipSegmentId, agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getIpAddress(
        advertiserId,
        ipSegmentId,
        queryString.stringify(payload),
      );
      return response?.data?.segmentIpDTO;
    },
    {
      enabled: !!ipSegmentId && !!agencyId,
    },
  );

  const { mutate: ipAddressCreate, isLoading: isLoadingipAddressCreate } = useMutation(
    async (values) => {
      const { name = '', ipAddress = '', enabled } = values;
      const payload = { name, ipAddress, enabled };
      const query = queryString.stringify({ agencyId });
      const response = !!ipSegmentId
        ? await Services.updateIpAddress(advertiserId, ipSegmentId, query, payload)
        : await Services.createIpAddress(advertiserId, query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: () => {
        enqueueSnackbar(
          !!ipSegmentId
            ? 'Segment Ip Address updated successfully.'
            : 'Segment Ip Address created successfully.',
          { variant: 'success' },
        );
        history.push(`/advertiser/ip-segment`);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      name: ipAddressData?.name || '',
      status: ipAddressData?.enabled
        ? { label: ipAddressData?.enabled, value: ipAddressData?.enabled }
        : {},
      ipAddress: ipAddressData?.ipAddress || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Segment name is required.'),
      status: Yup.object().required('Status is required').nullable(),
    }),
    onSubmit: (values) => {
      ipAddressCreate({
        ...values,
        enabled: values.status.value, // Ensure enabled is sent correctly
      });
    },
  });

  useEffect(() => {
    if (!isEmpty(ipAddressData)) {
      setFieldValue('name', ipAddressData.name || '');
      setFieldValue('ipAddress', ipAddressData.ipAddress || '');
      setFieldValue(
        'status',
        SGEMENT_STATUS.find((item) => item.value === ipAddressData.enabled) || SGEMENT_STATUS[0],
      );
    }
  }, [ipAddressData, setFieldValue]);
  const tncLoader = tncUpdated === 'true' && isLoadingipAddressCreate;

  return (
    <div className="col-10 p-0 bg-white rounded shadow m-auto mb-4">
      <Paper className="pt-3 p-4 mn-h-200">
        <div className="d-flex justify-content-center mb-3">
          <Typography color="textPrimary" variant="h5">
            {!!ipSegmentId ? 'Edit Ip Address' : 'Create New Ip Address'}
          </Typography>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} className="py-0">
              <NewTextField
                required
                info="Name The Ip Address Segment."
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Segment Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('name', event.target.value)}
                value={values.name}
                variant="outlined"
              />
              {serverErrors.name && <p className="text-danger mt-n2">{serverErrors.name}</p>}
            </Grid>
            <Grid item xs={12} sm={6} className="py-0">
              <NewSelect
                required
                info="Enable the status to start segment. Disable to pause it"
                options={SGEMENT_STATUS}
                value={values.status}
                onChange={(obj) => setFieldValue('status', obj)}
                placeholder="Status"
                className="py-2"
              />
              {serverErrors.enabled && <p className="text-danger mt-n2">{serverErrors.enabled}</p>}
            </Grid>
            <Grid item xs={12} className="py-0">
              <NewTextField
                required
                fullWidth
                error={Boolean(touched.ipAddress && errors.ipAddress)}
                helperText={touched.ipAddress && errors.ipAddress}
                info="Enter the Ip Address of the areas you want to target, if required."
                label="Ip Address"
                margin="normal"
                name="ipAddress"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('ipAddress', event.target.value)}
                value={values.ipAddress}
                variant="outlined"
                multiline
                rows={3}
              />
              {serverErrors.ipAddress && (
                <p className="text-danger mt-n2">{serverErrors.ipAddress}</p>
              )}
            </Grid>
            <Typography className="ml-3">
              Ip Address should be comma-separated (e.g. 152.59.7.248,152.59.8.250,etc.)
            </Typography>
          </Grid>
          <div className="mt-4 d-flex justify-content-end">
            <Button
              variant="contained"
              className="btn btn-secondary d-flex"
              onClick={() => history.push(`/advertiser/ip-segment`)}
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit" className="btn btn-primary d-flex ml-4">
              {!!ipSegmentId
                ? tncLoader
                  ? 'Updating'
                  : 'Update'
                : tncLoader
                ? 'Creating'
                : 'Create'}
            </Button>
          </div>
          {tncLoader && <OverlapLoader />}
        </form>
      </Paper>
    </div>
  );
};

export default IpSegmentCreate;
