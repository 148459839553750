import React, { Suspense, lazy } from 'react';
import desktop from './../../../assets/img/desktop.png';
import ndtv from './../../../assets/img/ndtv.png';
// import tablet_frame from './../../../assets/img/tablet_frame.png';
// import tabsm from './../../../assets/img/tabsm.png';
// import smartphoneicon from './../../../assets/icon/smartphoneicon.png';
// import desktopicon from './../../../assets/icon/desktopicon.png';
// import tableticon from './../../../assets/icon/tableticon.png';
const LazyImage = lazy(() => import('./LazyImage')); // Import LazyImage component
const BANNER_970_90 = ({ imageUrl = '' }) => {
  // const [currentView, setCurrentView] = useState('desktop');

  // const handleViewChange = (view) => {
  //   setCurrentView(view);
  // };
  return (
    <>
      {/* <div className="view-buttons">
        {/* <button
          className={`btn1 d-flex1 ${currentView === 'mobile' ? 'selected' : ''}`}
          onClick={() => handleViewChange('mobile')}
        >
          <img src={smartphoneicon} alt="" className="icons" />
        </button> */}
      {/* <button
          className={`btn1 d-flex1 ${currentView === 'desktop' ? 'selected' : ''}`}
          onClick={() => handleViewChange('desktop')}
        >
          <img src={desktopicon} alt="" className="icons" />
        </button>
        <button
          className={`btn1 d-flex1 ${currentView === 'tablet' ? 'selected' : ''}`}
          onClick={() => handleViewChange('tablet')}
        >
          <img src={tableticon} alt="" className="icons" />
        </button>
      </div> */}
      {/* {desktop && currentView === 'desktop' && ( */}
      <div className="BANNER_970_90 desktop_app_wrapper">
        <img src={ndtv} alt="banner" className="app_image" />
        <img src={desktop} alt="desktop" className="frame_image w-100" />
        <Suspense>
          <LazyImage imageUrl={imageUrl} />
        </Suspense>
      </div>
      {/* )} */}
      {/* {tablet_frame && currentView === 'tablet' && (
        <div className="BANNER_970_90 tablet_app_wrapper">
          <img src={tabsm} alt="banner" className="app_image2" />
          <img src={tablet_frame} alt="tablet" className="frame_image w-100" />
          <Suspense>
            <LazyImage imageUrl={imageUrl} className="case2" />
          </Suspense>
        </div>
      )} */}
    </>
  );
};

export default BANNER_970_90;
