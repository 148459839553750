import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Typography } from '../common';
import Chip from '@material-ui/core/Chip';
import history from '../history';

export const ForgotPasswordForm = ({ handleOnSubmit, errorText }) => {
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
    }),
    onSubmit: handleOnSubmit,
  });

  return (
    <>
      <div className="d-flex justify-content-center">
        <Typography variant="h6">Forgot your password?</Typography>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          error={Boolean(formik.touched.email && formik.errors.email)}
          fullWidth
          helperText={formik.touched.email && formik.errors.email}
          label="Email"
          margin="normal"
          name="email"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          type="email"
          value={formik.values.email}
          variant="outlined"
        />

        {!!errorText && <Chip label={errorText} className="alert-danger w-100" />}
        {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'center', // Center the buttons horizontally
            alignItems: 'center',
            py: 2,
            maxHeight: '200px',
            overflow: 'auto',
          }}
        > */}
        {/* <Button
            color="secondary"
            className="btn btn-secondary mr-2"
            size="large"
            onClick={() => history.push(`/login`)}
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="btn btn-primary"
            size="large"
            type="submit"
            variant="contained"
          >
            Submit
          </Button> */}

        <div className="mt-4 d-flex justify-content-end align-items-center">
          {/* Right side - Back to sign in link and Submit button */}
          <div className="d-flex align-items-center">
            <Typography
              className="h6 cursor-pointer"
              variant="body2"
              onClick={() => history.push('/login')}
            >
              <span className="primary-color">Back to sign in</span>
            </Typography>
            <Button
              color="primary"
              className="btn btn-primary ml-4"
              size="large"
              type="submit"
              variant="contained"
            >
              Submit
            </Button>
          </div>
        </div>

        {/* </Box> */}
      </form>
    </>
  );
};
