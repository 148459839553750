import React, { Suspense, lazy } from 'react';
import mobile_frame from './../../../assets/img/mobile_frame.png';
import hindustan_times from './../../../assets/img/hindustanTimes.jpg';
// import desktop from './../../../assets/img/desktop.png';
// import javaTpoint from '../../../assets/img/javaTpoint.png';
// import tabsharekaro from './../../../assets/img/tabsharekaro.png';
// import tablet_frame from './../../../assets/img/tablet_frame.png';
// import smartphoneicon from './../../../assets/icon/smartphoneicon.png';
// import desktopicon from './../../../assets/icon/desktopicon.png';
// import tableticon from './../../../assets/icon/tableticon.png';
const LazyImage = lazy(() => import('./LazyImage'));

const BANNER_250_250 = ({ imageUrl = '' }) => {
  // const [currentView, setCurrentView] = useState('mobile');

  // const handleViewChange = (view) => {
  //   setCurrentView(view);
  // };

  return (
    <>
      {/* <div className="view-buttons">
        <button
          className={`btn1 d-flex1 ${currentView === 'mobile' ? 'selected' : ''}`}
          onClick={() => handleViewChange('mobile')}
        >
          <img src={smartphoneicon} alt="" className="icons" />
        </button>
        <button
          className={`btn1 d-flex1 ${currentView === 'desktop' ? 'selected' : ''}`}
          onClick={() => handleViewChange('desktop')}
        >
          <img src={desktopicon} alt="" className="icons" />
        </button>
        <button
          className={`btn1 d-flex1 ${currentView === 'tablet' ? 'selected' : ''}`}
          onClick={() => handleViewChange('tablet')}
        >
          <img src={tableticon} alt="" className="icons" />
        </button>
      </div> */}

      {/* {currentView === 'mobile' && mobile_frame && ( */}
      <div className="BANNER_250_250 mobile_app_wrapper">
        <img src={hindustan_times} alt="banner" className="app_image" />
        <img src={mobile_frame} alt="mobile" className="frame_image" />
        <Suspense>
          <LazyImage imageUrl={imageUrl} />
        </Suspense>
      </div>
      {/* // )} */}

      {/* {currentView === 'desktop' && desktop && (
        <div className="BANNER_250_250 desktop_app_wrapper">
          <img src={javaTpoint} alt="banner" className="app_image1" />
          <img src={desktop} alt="desktop" className="frame_image w-100" />
          <Suspense>
            <LazyImage imageUrl={imageUrl} className="case2" />
            <LazyImage imageUrl={imageUrl} className="case3" />
            <LazyImage imageUrl={imageUrl} className="case4" />
          </Suspense>
        </div>
      )} */}

      {/* {currentView === 'tablet' && tablet_frame && (
        <div className=" BANNER_250_250 tablet_app_wrapper">
          <img src={tabsharekaro} alt="banner" className="app_image2" />
          <img src={tablet_frame} alt="tablet" className="frame_image w-100" />
          <Suspense>
            <LazyImage imageUrl={imageUrl} className="case5" />
          </Suspense>
        </div>
      )} */}
    </>
  );
};

export default BANNER_250_250;
