import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import './page-skeleton.scss';
import {
  BANNER_120_240,
  // BANNER_120_600,
  // BANNER_160_600,
  BANNER_180_150,
  BANNER_200_200,
  BANNER_250_250,
  BANNER_300_250,
  // BANNER_300_600,
  // BANNER_300_1050,
  BANNER_320_50,
  BANNER_320_100,
  BANNER_320_320,
  BANNER_320_480,
  // BANNER_336_280,
  BANNER_468_60,
  // BANNER_480_320,
  BANNER_728_90,
  // BANNER_768_1024,
  BANNER_970_90,
  // BANNER_970_250,
} from './preview-pages';

const PageSkeleton = ({ imageUrl, bannerSize, handleClose }) => {
  const bannerSizeKey = `KEY_${bannerSize?.formatName}`;

  const getBannerTemplate = (imageUrl, bannerSizeKey) => {
    switch (bannerSizeKey) {
      case 'KEY_728x90':
        return <BANNER_728_90 imageUrl={imageUrl} />;
      case 'KEY_300x250':
        return <BANNER_300_250 imageUrl={imageUrl} />;
      case 'KEY_250x250':
        return <BANNER_250_250 imageUrl={imageUrl} />;
      case 'KEY_320x50':
        return <BANNER_320_50 imageUrl={imageUrl} />;
      case 'KEY_180x150':
        return <BANNER_180_150 imageUrl={imageUrl} />;
      case 'KEY_320x100':
        return <BANNER_320_100 imageUrl={imageUrl} />;
      case 'KEY_200x200':
        return <BANNER_200_200 imageUrl={imageUrl} />;
      // case 'KEY_120x600':
      //   return <BANNER_120_600 imageUrl={imageUrl} />;
      case 'KEY_468x60':
        return <BANNER_468_60 imageUrl={imageUrl} />;
      case 'KEY_320x480':
        return <BANNER_320_480 imageUrl={imageUrl} />;
      // case 'KEY_300x600':
      //   return <BANNER_300_600 imageUrl={imageUrl} />;
      // case 'KEY_160x600':
      //   return <BANNER_160_600 imageUrl={imageUrl} />;
      // case 'KEY_480x320':
      //   return <BANNER_480_320 imageUrl={imageUrl} />;
      case 'KEY_970x90':
        return <BANNER_970_90 imageUrl={imageUrl} />;
      // case 'KEY_970x250':
      //   return <BANNER_970_250 imageUrl={imageUrl} />;
      // case 'KEY_336x280':
      //   return <BANNER_336_280 imageUrl={imageUrl} />;
      case 'KEY_320x320':
        return <BANNER_320_320 imageUrl={imageUrl} />;
      // case 'KEY_768x1024':
      //   return <BANNER_768_1024 imageUrl={imageUrl} />;
      case 'KEY_120x240':
        return <BANNER_120_240 imageUrl={imageUrl} />;
      // case 'KEY_300x1050':
      //   return <BANNER_300_1050 imageUrl={imageUrl} />;
      default:
        return <div className="mn-h-400">NOT FOUND</div>;
    }
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="xl"
      open={true}
      onClose={() => handleClose()}
      className="preview-page"
    >
      <Box className="d-flex justify-content-between px-2">
        <Typography variant="h6">Ad Preview</Typography>
        <IconButton aria-label="close" onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className="preview-wrapper">{getBannerTemplate(imageUrl, bannerSizeKey)}</Box>
    </Dialog>
  );
};

export default PageSkeleton;
