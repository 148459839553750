import React from 'react';
import { Chart } from '../../components';
import Services from '../../service-utils/services';
import getSessionData from '../../service-utils/session-util';
import queryString from 'query-string';
import { useQuery } from 'react-query';
import { orderBy } from 'lodash';
import { borderColor } from '../../common/utils';
import { externalTooltipHandler } from '../../components/chart/index';

export const StateImpressionChart = (props) => {
  const { agencyId } = getSessionData();

  const { data } = useQuery(
    ['GEO_IMPRESSION_CHART', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.stateWiseImpressions(queryString.stringify(payload));
      return response.data?.graphDataList;
    },
    {
      enabled: !!agencyId,
    },
  );
  const sortedData =
    orderBy(
      data?.map((item) => ({ ...item, name: item.label, y: item.value })),
      ['value'],
      ['asc'],
    ) || [];

  const Data = {
    datasets: [
      {
        fill: true,
        label: 'Impressions',
        data: sortedData.map((item) => item.value),
        backgroundColor: borderColor,
      },
    ],
    labels: sortedData.map((item) => item.descList[0]),
  };
  const options = {
    interaction: {
      mode: 'index',
      intersect: false,
      callbacks: {
        label: (tooltipItem = {}) => {
          const {
            dataset: { label = '', data = [] },
            dataIndex,
          } = tooltipItem || {};
          return `${label} : ${data[dataIndex]}`;
        },
      },
    },
    scales: {
      x: {
        ticks: {
          callback: (value, index, values) => sortedData[index]?.label || value,
        },
      },
    },
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        enabled: false,
        position: 'nearest',
        external: externalTooltipHandler,
      },
    },
  };
  return (
    <div>
      <Chart type="Bar" chartData={Data} options={options} height="250px" width="500px" />
    </div>
  );
};
